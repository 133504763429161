"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_COLLABORATORS = exports.UPDATE_ROOM = exports.WS_VALIDATION_ERROR = exports.WS_CONNECTION_ERROR = exports.OPEN_BOARD_DOCUMENT = exports.DRAWER_TASK = exports.BOARD_CHANGE = exports.ADD_LAWSUIT = exports.CLEAR_BOARD = exports.CLEAR_BOARD_STATE = exports.CHANGE_ITEM_WIDTH_FAILED = exports.CHANGE_ITEM_WIDTH_SUCCESS = exports.CHANGE_ITEM_WIDTH_LOADING = exports.GET_COLUMN_DROPDOWN_OPTIONS_FAILED = exports.GET_COLUMN_DROPDOWN_OPTIONS_SUCCESS = exports.GET_COLUMN_DROPDOWN_OPTIONS_LOADING = exports.DELETE_BOARD_FAILED = exports.DELETE_BOARD_SUCCESS = exports.DELETE_BOARD_LOADING = exports.PUT_BOARD_FAILED = exports.PUT_BOARD_SUCCESS = exports.PUT_BOARD_LOADING = exports.POST_BOARD_FAILED = exports.POST_BOARD_SUCCESS = exports.POST_BOARD_LOADING = exports.GET_BOARD_FAILED = exports.GET_BOARD_SUCCESS = exports.GET_BOARD_LOADING = exports.IMPORT_BOARD_TEMPLATE_WS_SUCCESS = exports.IMPORT_BOARD_TEMPLATE_SUCCESS = exports.IMPORT_BOARD_TEMPLATE_FAILED = exports.IMPORT_BOARD_TEMPLATE_LOADING = exports.IMPORT_BOARD_AI_FILES_FAILED = exports.IMPORT_BOARD_AI_FILES_SUCCESS = exports.IMPORT_BOARD_AI_FILES_LOADING = exports.IMPORT_BOARD_EXCEL_FAILED = exports.IMPORT_BOARD_EXCEL_SUCCESS = exports.IMPORT_BOARD_EXCEL_LOADING = exports.EXPORT_BOARD_FAILED = exports.EXPORT_BOARD_SUCCESS = exports.EXPORT_BOARD_LOADING = exports.GET_BOARDS_DROPDOWN_FAILED = exports.GET_BOARDS_DROPDOWN_SUCCESS = exports.GET_BOARDS_DROPDOWN_LOADING = exports.GET_BOARDS_MY_WORK_FAILED = exports.GET_BOARDS_MY_WORK_SUCCESS = exports.GET_BOARDS_MY_WORK_LOADING = exports.GET_BOARDS_FAILED = exports.GET_BOARDS_SUCCESS = exports.GET_BOARDS_LOADING = void 0;
exports.BOARD_CLOSE_MODAL = exports.BOARD_OPEN_MODAL = exports.BOARD_TOGGLE_IS_MANUEL_INDEX_LOADING = exports.BOARD_TOGGLE_IS_MANUEL_INDEX_SUCCESS = exports.BOARD_TOGGLE_IS_MANUEL_INDEX_FAILED = exports.BOARD_REMOVE_SHARED_USER_FAILED = exports.BOARD_REMOVE_SHARED_USER_SUCCESS = exports.BOARD_REMOVE_SHARED_USER_LOADING = exports.BOARD_TOGGLE_COMPLETE_LOADING = exports.BOARD_TOGGLE_COMPLETE_SUCCESS = exports.BOARD_TOGGLE_COMPLETE_FAILED = exports.BOARD_EDITABLE_UPDATED = exports.SHARE_BOARD_FAILED = exports.SHARE_BOARD_LOADING = exports.SHARE_BOARD_SUCCESS = exports.CLEAR_RECENT_BOARD = exports.GET_RECENT_BOARD_FAILED = exports.GET_RECENT_BOARD_SUCCESS = exports.GET_RECENT_BOARD_LOADING = exports.BOARD_DUPLICATE_SUCCESS = exports.BOARD_DUPLICATE_LOADING = exports.BOARD_DUPLICATE_FAILED = exports.COLLABORATOR_TOGGLE_ACTIVE = exports.COLLABORATOR_LEAVE_ROOM = exports.COLLABORATOR_JOIN_ROOM = exports.RESET_COLLABORATORS = void 0;
exports.GET_BOARDS_LOADING = "GET_BOARDS_LOADING";
exports.GET_BOARDS_SUCCESS = "GET_BOARDS_SUCCESS";
exports.GET_BOARDS_FAILED = "GET_BOARDS_FAILED";
exports.GET_BOARDS_MY_WORK_LOADING = "GET_BOARDS_MY_WORK_LOADING";
exports.GET_BOARDS_MY_WORK_SUCCESS = "GET_BOARDS_MY_WORK_SUCCESS";
exports.GET_BOARDS_MY_WORK_FAILED = "GET_BOARDS_MY_WORK_FAILED";
exports.GET_BOARDS_DROPDOWN_LOADING = "GET_BOARDS_DROPDOWN_LOADING";
exports.GET_BOARDS_DROPDOWN_SUCCESS = "GET_BOARDS_DROPDOWN_SUCCESS";
exports.GET_BOARDS_DROPDOWN_FAILED = "GET_BOARDS_DROPDOWN_FAILED";
exports.EXPORT_BOARD_LOADING = "EXPORT_BOARD_LOADING";
exports.EXPORT_BOARD_SUCCESS = "EXPORT_BOARD_SUCCESS";
exports.EXPORT_BOARD_FAILED = "EXPORT_BOARD_FAILED";
exports.IMPORT_BOARD_EXCEL_LOADING = "IMPORT_BOARD_EXCEL_LOADING";
exports.IMPORT_BOARD_EXCEL_SUCCESS = "IMPORT_BOARD_EXCEL_SUCCESS";
exports.IMPORT_BOARD_EXCEL_FAILED = "IMPORT_BOARD_EXCEL_FAILED";
exports.IMPORT_BOARD_AI_FILES_LOADING = "IMPORT_BOARD_AI_FILES_LOADING";
exports.IMPORT_BOARD_AI_FILES_SUCCESS = "IMPORT_BOARD_AI_FILES_SUCCESS";
exports.IMPORT_BOARD_AI_FILES_FAILED = "IMPORT_BOARD_AI_FILES_FAILED";
exports.IMPORT_BOARD_TEMPLATE_LOADING = "IMPORT_BOARD_TEMPLATE_LOADING";
exports.IMPORT_BOARD_TEMPLATE_FAILED = "IMPORT_BOARD_TEMPLATE_FAILED";
exports.IMPORT_BOARD_TEMPLATE_SUCCESS = "IMPORT_BOARD_TEMPLATE_SUCCESS";
exports.IMPORT_BOARD_TEMPLATE_WS_SUCCESS = "IMPORT_BOARD_TEMPLATE_WS_SUCCESS";
exports.GET_BOARD_LOADING = "GET_BOARD_LOADING";
exports.GET_BOARD_SUCCESS = "GET_BOARD_SUCCESS";
exports.GET_BOARD_FAILED = "GET_BOARD_FAILED";
exports.POST_BOARD_LOADING = "POST_BOARD_LOADING";
exports.POST_BOARD_SUCCESS = "POST_BOARD_SUCCESS";
exports.POST_BOARD_FAILED = "POST_BOARD_FAILED";
exports.PUT_BOARD_LOADING = "PUT_BOARD_LOADING";
exports.PUT_BOARD_SUCCESS = "PUT_BOARD_SUCCESS";
exports.PUT_BOARD_FAILED = "PUT_BOARD_FAILED";
exports.DELETE_BOARD_LOADING = "DELETE_BOARD_LOADING";
exports.DELETE_BOARD_SUCCESS = "DELETE_BOARD_SUCCESS";
exports.DELETE_BOARD_FAILED = "DELETE_BOARD_FAILED";
exports.GET_COLUMN_DROPDOWN_OPTIONS_LOADING = "GET_COLUMN_DROPDOWN_OPTIONS_LOADING";
exports.GET_COLUMN_DROPDOWN_OPTIONS_SUCCESS = "GET_COLUMN_DROPDOWN_OPTIONS_SUCCESS";
exports.GET_COLUMN_DROPDOWN_OPTIONS_FAILED = "GET_COLUMN_DROPDOWN_OPTIONS_FAILED";
exports.CHANGE_ITEM_WIDTH_LOADING = "CHANGE_ITEM_WIDTH_LOADING";
exports.CHANGE_ITEM_WIDTH_SUCCESS = "CHANGE_ITEM_WIDTH_SUCCESS";
exports.CHANGE_ITEM_WIDTH_FAILED = "CHANGE_ITEM_WIDTH_FAILED";
exports.CLEAR_BOARD_STATE = "CLEAR_BOARD_STATE";
exports.CLEAR_BOARD = "CLEAR_BOARD";
exports.ADD_LAWSUIT = "BOARD_ADD_LAWSUIT";
exports.BOARD_CHANGE = "BOARD_CHANGE";
exports.DRAWER_TASK = "DRAWER_TASK";
exports.OPEN_BOARD_DOCUMENT = "OPEN_BOARD_DOCUMENT";
exports.WS_CONNECTION_ERROR = "WS_CONNECTION_ERROR";
exports.WS_VALIDATION_ERROR = "WS_VALIDATION_ERROR";
exports.UPDATE_ROOM = "UPDATE_ROOM";
exports.GET_COLLABORATORS = "GET_COLLABORATORS";
exports.RESET_COLLABORATORS = "RESET_COLLABORATORS";
exports.COLLABORATOR_JOIN_ROOM = "COLLABORATOR_JOIN_ROOM";
exports.COLLABORATOR_LEAVE_ROOM = "COLLABORATOR_LEAVE_ROOM";
exports.COLLABORATOR_TOGGLE_ACTIVE = "COLLABORATOR_TOGGLE_ACTIVE";
exports.BOARD_DUPLICATE_FAILED = "BOARD_DUPLICATE_FAILED";
exports.BOARD_DUPLICATE_LOADING = "BOARD_DUPLICATE_LOADING";
exports.BOARD_DUPLICATE_SUCCESS = "BOARD_DUPLICATE_SUCCESS";
exports.GET_RECENT_BOARD_LOADING = "GET_RECENT_BOARD_LOADING";
exports.GET_RECENT_BOARD_SUCCESS = "GET_RECENT_BOARD_SUCCESS";
exports.GET_RECENT_BOARD_FAILED = "GET_RECENT_BOARD_FAILED";
exports.CLEAR_RECENT_BOARD = "CLEAR_RECENT_BOARD";
exports.SHARE_BOARD_SUCCESS = "SHARE_BOARD_SUCCESS";
exports.SHARE_BOARD_LOADING = "SHARE_BOARD_LOADING";
exports.SHARE_BOARD_FAILED = "SHARE_BOARD_FAILED";
exports.BOARD_EDITABLE_UPDATED = "BOARD_EDITABLE_UPDATED";
exports.BOARD_TOGGLE_COMPLETE_FAILED = "BOARD_TOGGLE_COMPLETE_FAILED";
exports.BOARD_TOGGLE_COMPLETE_SUCCESS = "BOARD_TOGGLE_COMPLETE_SUCCESS";
exports.BOARD_TOGGLE_COMPLETE_LOADING = "BOARD_TOGGLE_COMPLETE_LOADING";
exports.BOARD_REMOVE_SHARED_USER_LOADING = "BOARD_REMOVE_SHARED_USER_LOADING";
exports.BOARD_REMOVE_SHARED_USER_SUCCESS = "BOARD_REMOVE_SHARED_USER_SUCCESS";
exports.BOARD_REMOVE_SHARED_USER_FAILED = "BOARD_REMOVE_SHARED_USER_FAILED";
exports.BOARD_TOGGLE_IS_MANUEL_INDEX_FAILED = "BOARD_TOGGLE_IS_MANUEL_INDEX_FAILED";
exports.BOARD_TOGGLE_IS_MANUEL_INDEX_SUCCESS = "BOARD_TOGGLE_IS_MANUEL_INDEX_SUCCESS";
exports.BOARD_TOGGLE_IS_MANUEL_INDEX_LOADING = "BOARD_TOGGLE_IS_MANUEL_INDEX_LOADING";
exports.BOARD_OPEN_MODAL = "BOARD_OPEN_MODAL";
exports.BOARD_CLOSE_MODAL = "BOARD_CLOSE_MODAL";
