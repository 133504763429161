"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.boardInitialLoadings = void 0;
var constants_1 = require("@api/constants");
var boardConstants_1 = require("./boardConstants");
var board_1 = require("@customTypes/reducers/board");
var taskGroupConstants_1 = require("./taskGroupConstants");
var taskConstants_1 = require("./taskConstants");
var viewConstants_1 = require("./viewConstants");
var columnConstants_1 = require("./columnConstants");
var cellConstants_1 = require("./cellConstants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var reducerHandlers_1 = require("./reducerHandlers");
var arrayMove_1 = require("@utils/arrayMove");
var automationConstants_1 = require("./automationConstants");
exports.boardInitialLoadings = __assign(__assign({}, constants_1.initialLoadings), { recents: false, import: false, postTaskGroup: false, share: false, show: false, column: false, view: false });
var initialState = {
    boards: [],
    myWork: board_1.initialMyWork,
    boardDropdownOptions: [],
    loadings: exports.boardInitialLoadings,
    cellLoadings: {},
    taskGroupLoadings: {},
    columnLoadings: {},
    board: board_1.initialBoard,
    recentBoards: [],
    specificBoardTags: [],
    taskGroups: [],
    collaborators: new Map(),
    columns: [],
    labels: [],
    drawerTask: null,
    files: [],
    documentOpen: {
        open: false,
        showForm: false,
    },
    dropDownOptions: {},
    room: "",
    isEditable: false,
    views: [],
    automations: [],
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // LOADINGS
        case boardConstants_1.GET_BOARDS_LOADING:
        case boardConstants_1.GET_BOARD_LOADING:
        case boardConstants_1.POST_BOARD_LOADING:
        case boardConstants_1.PUT_BOARD_LOADING:
        case boardConstants_1.DELETE_BOARD_LOADING:
        case taskGroupConstants_1.POST_TASK_GROUP_LOADING:
        case taskGroupConstants_1.PUT_TASK_GROUP_LOADING:
        case taskGroupConstants_1.COLLAPSE_TASK_GROUP_LOADING:
        case taskGroupConstants_1.UPDATE_TASK_GROUP_INDEX_LOADING:
        case taskGroupConstants_1.DUPLICATE_TASK_GROUP_LOADING:
        case taskGroupConstants_1.EXPORT_TASK_GROUP_LOADING:
        case taskConstants_1.PUT_TASK_LOADING:
        case taskConstants_1.DELETE_TASK_LOADING:
        case taskConstants_1.UPDATE_TASK_INDEX_LOADING:
        case columnConstants_1.POST_COLUMN_LOADING:
        case columnConstants_1.CHANGE_COLUMN_TITLE_LOADING:
        case columnConstants_1.CHANGE_COLUMN_DESCRIPTION_LOADING:
        case columnConstants_1.UPDATE_COLUMN_INDEX_LOADING:
        case boardConstants_1.CHANGE_ITEM_WIDTH_LOADING:
        case boardConstants_1.GET_COLUMN_DROPDOWN_OPTIONS_LOADING:
        case boardConstants_1.GET_RECENT_BOARD_LOADING:
        case taskConstants_1.DUPLICATE_TASK_LOADING:
        case cellConstants_1.GET_COLUMN_LABEL_LOADING:
        case taskConstants_1.MOVE_TASK_LOADING:
        case boardConstants_1.GET_BOARDS_DROPDOWN_LOADING:
        case taskConstants_1.EXPAND_TASK_LOADING:
        case columnConstants_1.UPDATE_COLUMN_WIDTH_LOADING:
        case cellConstants_1.ADD_COLUMN_LABEL_LOADING:
        case cellConstants_1.REMOVE_COLUMN_LABEL_LOADING:
        case boardConstants_1.BOARD_DUPLICATE_LOADING:
        case taskConstants_1.CONVERT_ITEM_TO_SUBTASK_LOADING:
        case taskConstants_1.MOVE_TO_OTHER_PARENT_TASK_LOADING:
        case taskConstants_1.CONVERT_SUBITEM_TO_TASK_LOADING:
        case columnConstants_1.UPDATE_NUMBER_AGGREGATOR_LOADING:
        case taskConstants_1.POST_TASK_FROM_MY_WORK_LOADING:
        case boardConstants_1.GET_BOARDS_MY_WORK_LOADING:
        case boardConstants_1.IMPORT_BOARD_EXCEL_LOADING:
        case boardConstants_1.IMPORT_BOARD_AI_FILES_LOADING:
        case boardConstants_1.IMPORT_BOARD_TEMPLATE_LOADING:
        case columnConstants_1.UPDATE_DATE_AGGREGATOR_LOADING:
        case columnConstants_1.UPDATE_DATE_RANGE_AGGREGATOR_LOADING:
        case taskGroupConstants_1.TOGGLE_COMPLETE_TASK_GROUP_LOADING:
        case boardConstants_1.BOARD_TOGGLE_COMPLETE_LOADING:
        case taskConstants_1.DUPLICATE_MULTIPLE_TASKS_LOADING:
        case viewConstants_1.POST_BOARD_VIEW_LOADING:
        case viewConstants_1.RENMAE_BOARD_VIEW_LOADING:
        case viewConstants_1.SET_AS_DEFAULT_BOARD_VIEW_LOADING:
        case viewConstants_1.DELETE_BOARD_VIEW_LOADING:
        case automationConstants_1.INDEX_AUTOMATION_LOADING:
        case boardConstants_1.BOARD_TOGGLE_IS_MANUEL_INDEX_LOADING:
        case automationConstants_1.STORE_AUTOMATION_LOADING:
        case "HANDLE_GANTT_CHANGES_LOADING":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        // CELL LOADING
        case cellConstants_1.STATUS_CELL_LOADING:
        case cellConstants_1.PERSON_CELL_LOADING:
        case cellConstants_1.TEXT_CELL_LOADING:
        case cellConstants_1.NUMBER_CELL_LOADING:
        case cellConstants_1.DATE_CELL_LOADING:
        case cellConstants_1.DATE_RANGE_CELL_LOADING:
        case cellConstants_1.CHECK_BOX_CELL_LOADING:
        case cellConstants_1.ADD_TIME_TRACKING_CELL_LOADING:
        case cellConstants_1.EDIT_TIME_TRACKING_CELL_LOADING:
        case cellConstants_1.REMOVE_TIME_TRACKING_CELL_LOADING:
        case cellConstants_1.CLOCK_CELL_LOADING:
        case cellConstants_1.LONG_TEXT_CELL_LOADING:
        case cellConstants_1.LINK_CELL_LOADING:
        case cellConstants_1.DROP_DOWN_CELL_LOADING:
        case cellConstants_1.LABEL_CELL_LOADING:
        case cellConstants_1.COUNTRY_CELL_LOADING:
        case cellConstants_1.PRIORITY_CELL_LOADING:
        case cellConstants_1.FILE_CELL_LOADING:
        case cellConstants_1.CLEAR_CELL_LOADING:
            return __assign(__assign({}, state), { cellLoadings: __assign(__assign({}, state.cellLoadings), (_b = {}, _b[action.loadingId] = true, _b)) });
        // FAILED
        case boardConstants_1.GET_BOARDS_FAILED:
        case boardConstants_1.GET_BOARD_FAILED:
        case boardConstants_1.POST_BOARD_FAILED:
        case boardConstants_1.PUT_BOARD_FAILED:
        case boardConstants_1.GET_BOARDS_DROPDOWN_FAILED:
        case boardConstants_1.DELETE_BOARD_FAILED:
        case taskConstants_1.DUPLICATE_TASK_FAILED:
        case taskConstants_1.POST_TASK_FROM_MY_WORK_FAILED:
        case taskGroupConstants_1.POST_TASK_GROUP_FAILED:
        case taskGroupConstants_1.PUT_TASK_GROUP_FAILED:
        case taskGroupConstants_1.COLLAPSE_TASK_GROUP_FAILED:
        case taskConstants_1.EXPAND_TASK_FAILED:
        case columnConstants_1.UPDATE_COLUMN_WIDTH_FAILED:
        case boardConstants_1.GET_RECENT_BOARD_FAILED:
        case boardConstants_1.GET_BOARDS_MY_WORK_FAILED:
        case boardConstants_1.IMPORT_BOARD_EXCEL_FAILED:
        case boardConstants_1.IMPORT_BOARD_AI_FILES_FAILED:
        case taskConstants_1.CONVERT_ITEM_TO_SUBTASK_FAILED:
        case boardConstants_1.IMPORT_BOARD_TEMPLATE_FAILED:
        case taskGroupConstants_1.DUPLICATE_TASK_GROUP_FAILED:
        case taskGroupConstants_1.EXPORT_TASK_GROUP_FAILED:
        case taskConstants_1.PUT_TASK_FAILED:
        case taskConstants_1.DELETE_TASK_FAILED:
        case boardConstants_1.BOARD_TOGGLE_IS_MANUEL_INDEX_FAILED:
        case columnConstants_1.POST_COLUMN_FAILED:
        case columnConstants_1.CHANGE_COLUMN_TITLE_FAILED:
        case columnConstants_1.CHANGE_COLUMN_DESCRIPTION_FAILED:
        case columnConstants_1.UPDATE_COLUMN_INDEX_FAILED:
        case taskConstants_1.MOVE_TASK_FAILED:
        case boardConstants_1.GET_COLUMN_DROPDOWN_OPTIONS_FAILED:
        case cellConstants_1.GET_COLUMN_LABEL_FAILED:
        case cellConstants_1.ADD_COLUMN_LABEL_FAILED:
        case cellConstants_1.REMOVE_COLUMN_LABEL_FAILED:
        case boardConstants_1.BOARD_DUPLICATE_FAILED:
        case boardConstants_1.CHANGE_ITEM_WIDTH_FAILED:
        case taskConstants_1.MOVE_TO_OTHER_PARENT_TASK_FAILED:
        case taskConstants_1.CONVERT_SUBITEM_TO_TASK_FAILED:
        case columnConstants_1.UPDATE_NUMBER_AGGREGATOR_FAILED:
        case columnConstants_1.UPDATE_DATE_AGGREGATOR_FAILED:
        case columnConstants_1.UPDATE_DATE_RANGE_AGGREGATOR_FAILED:
        case taskConstants_1.DUPLICATE_MULTIPLE_TASKS_FAILED:
        case viewConstants_1.POST_BOARD_VIEW_FAILED:
        case viewConstants_1.RENMAE_BOARD_VIEW_FAILED:
        case viewConstants_1.SET_AS_DEFAULT_BOARD_VIEW_FAILED:
        case viewConstants_1.DELETE_BOARD_VIEW_FAILED:
        case automationConstants_1.INDEX_AUTOMATION_FAILED:
        case automationConstants_1.STORE_AUTOMATION_FAILED:
        case "HANDLE_GANTT_CHANGES_FAILED":
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        // CELL FAILED
        case cellConstants_1.STATUS_CELL_FAILED:
        case cellConstants_1.PERSON_CELL_FAILED:
        case cellConstants_1.TEXT_CELL_FAILED:
        case cellConstants_1.NUMBER_CELL_FAILED:
        case cellConstants_1.DATE_CELL_FAILED:
        case cellConstants_1.DATE_RANGE_CELL_FAILED:
        case cellConstants_1.CLEAR_CELL_FAILED:
        case cellConstants_1.CHECK_BOX_CELL_FAILED:
        case cellConstants_1.ADD_TIME_TRACKING_CELL_FAILED:
        case cellConstants_1.EDIT_TIME_TRACKING_CELL_FAILED:
        case cellConstants_1.REMOVE_TIME_TRACKING_CELL_FAILED:
        case cellConstants_1.CLOCK_CELL_FAILED:
        case cellConstants_1.LONG_TEXT_CELL_FAILED:
        case cellConstants_1.LINK_CELL_FAILED:
        case cellConstants_1.FILE_CELL_FAILED:
        case cellConstants_1.PRIORITY_CELL_FAILED:
        case cellConstants_1.DROP_DOWN_CELL_FAILED:
        case cellConstants_1.LABEL_CELL_FAILED:
        case cellConstants_1.COUNTRY_CELL_FAILED:
            return __assign(__assign({}, state), { cellLoadings: (0, reducerHandlers_1.stopCellLoading)(state.cellLoadings, action.loadingId) });
        // EMPTY
        case boardConstants_1.CLEAR_BOARD:
            return __assign(__assign({}, state), { board: board_1.initialBoard });
        case boardConstants_1.CLEAR_BOARD_STATE:
            return initialState;
        // SUCCESS
        case boardConstants_1.GET_BOARDS_SUCCESS:
            return __assign(__assign({}, state), { boards: action.boards, loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = false, _c)) });
        case boardConstants_1.IMPORT_BOARD_EXCEL_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)), taskGroups: action.taskGroups, columns: action.columns });
        case boardConstants_1.IMPORT_BOARD_AI_FILES_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadingType] = false, _e)) });
        case boardConstants_1.IMPORT_BOARD_TEMPLATE_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadingType] = false, _f)), taskGroups: __spreadArray(__spreadArray([], state.taskGroups, true), action.taskGroups, true), columns: __spreadArray(__spreadArray([], state.columns, true), action.columns, true) });
        case boardConstants_1.IMPORT_BOARD_TEMPLATE_WS_SUCCESS:
            return __assign(__assign({}, state), { taskGroups: __spreadArray(__spreadArray([], state.taskGroups, true), action.taskGroups, true), columns: __spreadArray(__spreadArray([], state.columns, true), action.columns, true) });
        case boardConstants_1.GET_BOARD_SUCCESS:
            return __assign(__assign({}, state), { board: action.board, taskGroups: action.taskGroups, columns: action.columns, views: action.views, loadings: __assign(__assign({}, state.loadings), (_g = {}, _g[action.loadingType] = false, _g)) });
        case boardConstants_1.POST_BOARD_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_h = {}, _h[action.loadingType] = false, _h)) });
        case boardConstants_1.PUT_BOARD_SUCCESS:
            return __assign(__assign({}, state), { board: action.board, loadings: __assign(__assign({}, state.loadings), (_j = {}, _j[action.loadingType] = false, _j)) });
        case boardConstants_1.DELETE_BOARD_SUCCESS:
            return __assign(__assign({}, state), { boards: state.boards.filter(function (board) { return board.id !== action.boardId; }), loadings: __assign(__assign({}, state.loadings), (_k = {}, _k[action.loadingType] = false, _k)) });
        case boardConstants_1.BOARD_CHANGE:
            return __assign(__assign({}, state), { board: __assign(__assign({}, state.board), (_l = {}, _l[action.key] = action.value, _l)) });
        case viewConstants_1.POST_BOARD_VIEW_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), views: __spreadArray(__spreadArray([], state.views, true), [action.view], false) });
        case viewConstants_1.RENMAE_BOARD_VIEW_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), views: state.views.map(function (view) {
                    return view.id === action.viewId ? __assign(__assign({}, view), { title: action.title }) : view;
                }) });
        case viewConstants_1.SET_AS_DEFAULT_BOARD_VIEW_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), views: state.views
                    .map(function (view) {
                    return view.id === action.viewId
                        ? __assign(__assign({}, view), { isDefault: true }) : __assign(__assign({}, view), { isDefault: false });
                })
                    .sort(function (a, _) { return (a.isDefault ? -1 : 1); }) });
        case viewConstants_1.DELETE_BOARD_VIEW_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), views: state.views.filter(function (view) { return view.id !== action.viewId; }) });
        case boardConstants_1.GET_RECENT_BOARD_SUCCESS:
            return __assign(__assign({}, state), { recentBoards: action.boards, loadings: __assign(__assign({}, state.loadings), { recents: false }) });
        case boardConstants_1.CLEAR_RECENT_BOARD:
            return __assign(__assign({}, state), { recentBoards: [], loadings: __assign(__assign({}, state.loadings), { recents: false }) });
        case taskGroupConstants_1.POST_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: __spreadArray(__spreadArray([], state.taskGroups, true), [action.taskGroup], false) });
        case taskGroupConstants_1.PUT_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
                    return taskGroup.id === action.taskGroup.id ? action.taskGroup : taskGroup;
                }) });
        case taskGroupConstants_1.DELETE_TASK_GROUP_LOADING:
        case taskGroupConstants_1.DELETE_TASK_GROUP_FAILED:
            return (0, reducerHandlers_1.handleDeleteTaskGroup)(state, action);
        case taskGroupConstants_1.DELETE_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.filter(function (taskGroup) { return taskGroup.id !== action.taskGroupId; }) });
        case taskGroupConstants_1.COLLAPSE_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
                    return taskGroup.id === action.taskGroupId
                        ? __assign(__assign({}, taskGroup), { collapsed: action.collapsed }) : taskGroup;
                }) });
        case taskGroupConstants_1.DUPLICATE_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: __spreadArray(__spreadArray([], state.taskGroups, true), [action.taskGroup], false) });
        case boardConstants_1.GET_BOARDS_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { boardDropdownOptions: action.boardDropdownOptions, loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case taskGroupConstants_1.EXPORT_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case taskGroupConstants_1.TASK_GROUP_CHANGE:
            return __assign(__assign({}, state), { taskGroups: state.taskGroups.map(function (taskGroup) {
                    var _a;
                    return taskGroup.id === action.taskGroupId
                        ? __assign(__assign({}, taskGroup), (_a = {}, _a[action.key] = action.value, _a)) : taskGroup;
                }) });
        case taskGroupConstants_1.UPDATE_TASK_GROUP_INDEX_OPTIMISTIC:
        case taskGroupConstants_1.COLLABORATOR_UPDATE_TASK_GROUP_INDEX_SUCCESS:
        case taskGroupConstants_1.UPDATE_TASK_GROUP_INDEX_FAILED: {
            var taskGroups = (0, arrayMove_1.arrayMoveElement)(state.taskGroups, action.from, action.to).map(function (group, index) { return (__assign(__assign({}, group), { index: index })); });
            return __assign(__assign({}, state), { taskGroups: taskGroups });
        }
        case boardConstants_1.CHANGE_ITEM_WIDTH_SUCCESS:
            return __assign(__assign({}, state), { board: __assign(__assign({}, state.board), { itemWidth: action.itemWidth }) });
        case columnConstants_1.UPDATE_NUMBER_AGGREGATOR_SUCCESS:
        case columnConstants_1.UPDATE_DATE_RANGE_AGGREGATOR_SUCCESS:
        case columnConstants_1.UPDATE_DATE_AGGREGATOR_SUCCESS: {
            var newColumns = state.columns.map(function (column) {
                return column.id === action.boardColumnId
                    ? __assign(__assign({}, column), { aggregator: action.aggregator }) : column;
            });
            return __assign(__assign({}, state), { columns: newColumns });
        }
        case taskConstants_1.POST_TASK_SUCCESS:
            return (0, reducerHandlers_1.handlePostTask)(state, action);
        case taskConstants_1.POST_TASK_FAILED:
            return (0, reducerHandlers_1.handlePostTaskFailed)(state, action);
        case taskConstants_1.POST_TASK_LOADING:
            return (0, reducerHandlers_1.handlePostTaskLoading)(state, action);
        case taskConstants_1.PUT_TASK_SUCCESS:
            return (0, reducerHandlers_1.handlePutTask)(state, action);
        case taskConstants_1.MOVE_TASK_SUCCESS:
            return (0, reducerHandlers_1.handleMoveTask)(state, action);
        case taskConstants_1.DUPLICATE_TASK_SUCCESS:
            return (0, reducerHandlers_1.handleDuplicateTask)(state, action);
        case taskConstants_1.DELETE_TASK_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
                    if (Object.keys(action.deltedTaskInTaskGroups).includes(taskGroup.id.toString())) {
                        return __assign(__assign({}, taskGroup), { tasks: action.deltedTaskInTaskGroups[taskGroup.id] });
                    }
                    return taskGroup;
                }) });
        case columnConstants_1.POST_COLUMN_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), columns: __spreadArray(__spreadArray([], state.columns, true), [action.column], false), taskGroups: state.taskGroups.map(function (taskGroup) {
                    var _a;
                    taskGroup.aggregation = __assign(__assign({}, taskGroup.aggregation), (_a = {}, _a["".concat(action.column.columnType, "-").concat(action.column.id)] = "size", _a));
                    return taskGroup;
                }) });
        case columnConstants_1.CHANGE_COLUMN_TITLE_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), columns: state.columns.map(function (column) {
                    return column.id === action.column.id ? action.column : column;
                }) });
        case columnConstants_1.CHANGE_COLUMN_DESCRIPTION_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), columns: state.columns.map(function (column) {
                    return column.id === action.column.id ? action.column : column;
                }) });
        case boardConstants_1.GET_BOARDS_MY_WORK_SUCCESS:
            return __assign(__assign({}, state), { myWork: action.myWork, loadings: __assign(__assign({}, state.loadings), (_m = {}, _m[action.loadingType] = false, _m)) });
        case columnConstants_1.DELETE_COLUMN_LOADING:
        case columnConstants_1.DELETE_COLUMN_FAILED:
            return (0, reducerHandlers_1.handleDeleteColumn)(state, action);
        case columnConstants_1.DELETE_COLUMN_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), columns: state.columns.filter(function (column) { return column.id !== action.columnId; }) });
        case columnConstants_1.UPDATE_COLUMN_INDEX_SUCCESS: {
            var newColumns = Array.from(state.columns);
            var removed = newColumns.splice(action.from, 1)[0];
            newColumns.splice(action.to, 0, removed);
            return __assign(__assign({}, state), { columns: newColumns, loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        }
        case cellConstants_1.PERSON_CELL_SUCCESS:
        case cellConstants_1.ADD_TIME_TRACKING_CELL_SUCCESS:
        case cellConstants_1.EDIT_TIME_TRACKING_CELL_SUCCESS:
        case cellConstants_1.REMOVE_TIME_TRACKING_CELL_SUCCESS:
        case cellConstants_1.FETCH_LATEST_COMMENT:
            return (0, reducerHandlers_1.handleCellSuccess)(state, action);
        case cellConstants_1.COUNTRY_CELL_SUCCESS:
        case cellConstants_1.DROP_DOWN_CELL_SUCCESS:
        case cellConstants_1.DATE_RANGE_CELL_SUCCESS:
        case cellConstants_1.NUMBER_CELL_SUCCESS:
        case cellConstants_1.FILE_CELL_SUCCESS:
        case cellConstants_1.LINK_CELL_SUCCESS:
        case cellConstants_1.TEXT_CELL_SUCCESS:
        case cellConstants_1.CLOCK_CELL_SUCCESS:
        case cellConstants_1.DATE_CELL_SUCCESS:
        case cellConstants_1.LONG_TEXT_CELL_SUCCESS:
        case cellConstants_1.PRIORITY_CELL_SUCCESS:
        case cellConstants_1.LABEL_CELL_SUCCESS:
        case cellConstants_1.CHECK_BOX_CELL_SUCCESS:
        case cellConstants_1.STATUS_CELL_SUCCESS:
            return (0, reducerHandlers_1.handleMultipleCellsSuccess)(state, action);
        case cellConstants_1.CLEAR_CELL_SUCCESS:
            return (0, reducerHandlers_1.handleClearCell)(state, action);
        case cellConstants_1.GET_COLUMN_LABEL_SUCCESS:
            return __assign(__assign({}, state), { labels: action.labels, loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case cellConstants_1.ADD_COLUMN_LABEL_SUCCESS:
            return __assign(__assign({}, state), { labels: __spreadArray(__spreadArray([], state.labels, true), [action.label], false), loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case cellConstants_1.REMOVE_COLUMN_LABEL_SUCCESS:
            return __assign(__assign({}, state), { labels: state.labels.filter(function (label) { return label.id !== action.labelId; }), loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case boardConstants_1.DRAWER_TASK:
            return __assign(__assign({}, state), { drawerTask: action.task });
        case boardConstants_1.OPEN_BOARD_DOCUMENT:
            return __assign(__assign({}, state), { documentOpen: action.open });
        case columnConstants_1.UPDATE_COLUMN_WIDTH_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), columns: state.columns.map(function (column) {
                    return column.id === action.boardColumnId
                        ? __assign(__assign({}, column), { width: action.width }) : column;
                }) });
        case taskConstants_1.EXPAND_TASK_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
                    return taskGroup.id === action.taskGroupId
                        ? __assign(__assign({}, taskGroup), { tasks: taskGroup.tasks.map(function (task) {
                                return task.id === action.taskId
                                    ? __assign(__assign({}, task), { expanded: action.expanded }) : task;
                            }) }) : taskGroup;
                }) });
        case taskConstants_1.POST_TASK_FROM_MY_WORK_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), myWork: {
                    past: __spreadArray(__spreadArray([], state.myWork.past, true), action.myWork.past, true),
                    today: __spreadArray(__spreadArray([], state.myWork.today, true), action.myWork.today, true),
                    thisWeek: __spreadArray(__spreadArray([], state.myWork.thisWeek, true), action.myWork.thisWeek, true),
                    nextWeek: __spreadArray(__spreadArray([], state.myWork.nextWeek, true), action.myWork.nextWeek, true),
                    thisMonth: __spreadArray(__spreadArray([], state.myWork.thisMonth, true), action.myWork.thisMonth, true),
                    later: __spreadArray(__spreadArray([], state.myWork.later, true), action.myWork.later, true),
                    withoutDate: __spreadArray(__spreadArray([], state.myWork.withoutDate, true), action.myWork.withoutDate, true),
                } });
        case cellConstants_1.SET_FILE_UPLOADS:
            return __assign(__assign({}, state), { files: action.files });
        case cellConstants_1.UPDATE_FILE_PROGRESS_UPLOADS: {
            var newFiles = state.files.map(function (file) {
                if (("id" in file.file && file.file.id === action.name) ||
                    file.file.name === action.name) {
                    return __assign(__assign({}, file), { progress: action.progress });
                }
                return file;
            });
            return __assign(__assign({}, state), { files: newFiles });
        }
        case boardConstants_1.GET_COLUMN_DROPDOWN_OPTIONS_SUCCESS:
            return __assign(__assign({}, state), { dropDownOptions: action.columnDropdownOptions, loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case taskConstants_1.MOVE_TO_OTHER_PARENT_TASK_SUCCESS:
            return (0, reducerHandlers_1.handleMoveTaskToOtherParent)(state, action);
        case taskConstants_1.CONVERT_SUBITEM_TO_TASK_SUCCESS:
            return (0, reducerHandlers_1.handleConvertSubItemToTask)(state, action);
        case taskConstants_1.CONVERT_ITEM_TO_SUBTASK_SUCCESS:
            return (0, reducerHandlers_1.handleConvertItemToSubTask)(state, action);
        case (0, folderConstants_1.postFolderFailed)("boards"):
        case (0, folderConstants_1.putFolderTitleFailed)("boards"):
        case (0, folderConstants_1.putFolderLocationFailed)("boards"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("boards"):
        case (0, folderConstants_1.deleteFolderFailed)("boards"):
        case (0, folderConstants_1.duplicateFolderFailed)("boards"):
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("boards"):
        case (0, folderConstants_1.putFolderLocationLoading)("boards"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("boards"):
        case (0, folderConstants_1.putFolderTitleLoading)("boards"):
        case (0, folderConstants_1.postFolderLoading)("boards"):
        case (0, folderConstants_1.duplicateFolderLoading)("boards"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_o = {}, _o[action.loadings] = true, _o)) });
        case (0, folderConstants_1.postFolderSuccess)("boards"):
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: __spreadArray([action.folder], state.boards, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("boards"):
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: state.boards.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("boards"):
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: state.boards.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("boards"):
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: state.boards.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("boards"): {
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: state.boards
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: state.boards
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.duplicateFolderSuccess)("boards"):
            return __assign(__assign({}, state), { loadings: exports.boardInitialLoadings, boards: __spreadArray(__spreadArray([], action.folder, true), state.boards, true) });
        case boardConstants_1.BOARD_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { boards: action.board ? __spreadArray(__spreadArray([], state.boards, true), [action.board], false) : state.boards, loadings: __assign(__assign({}, state.loadings), (_p = {}, _p[action.loadingType || "post"] = false, _p)) });
        case boardConstants_1.COLLABORATOR_JOIN_ROOM: {
            var collaborators = new Map(state.collaborators);
            collaborators.set(action.id, action.collaborator);
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case boardConstants_1.COLLABORATOR_LEAVE_ROOM: {
            var collaborators = new Map(state.collaborators);
            collaborators.delete(action.id);
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case boardConstants_1.COLLABORATOR_TOGGLE_ACTIVE: {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.active = action.active;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case boardConstants_1.UPDATE_ROOM:
            return __assign(__assign({}, state), { room: action.room });
        case boardConstants_1.GET_COLLABORATORS:
            return __assign(__assign({}, state), { collaborators: action.collaborators });
        case taskConstants_1.UPDATE_TASK_INDEX_FAILED:
        case taskConstants_1.UPDATE_TASK_INDEX_OPTIMISTIC:
        case taskConstants_1.COLLABORATOR_UPDATE_TASK_INDEX_SUCCESS:
            return (0, reducerHandlers_1.handleIndexUpdate)(state, action);
        case taskConstants_1.TASK_COMMENT_COUNT:
            return __assign(__assign({}, state), { taskGroups: state.taskGroups.map(function (taskGroup) {
                    if (taskGroup.id === action.task.taskGroupId) {
                        return __assign(__assign({}, taskGroup), { tasks: taskGroup.tasks.map(function (item) {
                                if (action.task.parentId && item.id === action.task.parentId) {
                                    return __assign(__assign({}, item), { tasks: item.tasks.map(function (subItem) {
                                            if (subItem.id === action.task.id) {
                                                return __assign(__assign({}, subItem), { commentCount: subItem.commentCount + 1 });
                                            }
                                            return subItem;
                                        }) });
                                }
                                if (!action.task.parentId && item.id === action.task.id) {
                                    return __assign(__assign({}, item), { commentCount: item.commentCount + 1 });
                                }
                                return item;
                            }) });
                    }
                    return taskGroup;
                }) });
        case boardConstants_1.BOARD_EDITABLE_UPDATED:
            return __assign(__assign({}, state), { isEditable: action.isEditable });
        case taskGroupConstants_1.TOGGLE_COMPLETE_TASK_GROUP_FAILED:
            return __assign(__assign({}, state), { loadings: action.loadingType
                    ? __assign(__assign({}, state.loadings), (_q = {}, _q[action.loadingType] = false, _q)) : state.loadings });
        case taskGroupConstants_1.TOGGLE_COMPLETE_TASK_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
                    return taskGroup.id === action.taskGroupId
                        ? __assign(__assign({}, taskGroup), { collapsed: action.collapsed, completed: action.completed }) : taskGroup;
                }) });
        case boardConstants_1.BOARD_TOGGLE_COMPLETE_SUCCESS:
            return __assign(__assign({}, state), { board: __assign(__assign({}, state.board), { completed: action.completed }), boards: state.boards.map(function (board) {
                    return board.id === action.boardId ? __assign(__assign({}, board), { completed: action.completed }) : board;
                }), loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case boardConstants_1.BOARD_TOGGLE_IS_MANUEL_INDEX_SUCCESS:
            return __assign(__assign({}, state), { board: __assign(__assign({}, state.board), { isManuel: action.isManuel }), boards: state.boards.map(function (board) {
                    return board.id === action.boardId ? __assign(__assign({}, board), { isManuel: action.isManuel }) : board;
                }), loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType), taskGroups: action.taskGroups });
        case taskConstants_1.DUPLICATE_MULTIPLE_TASKS_SUCCESS:
            return (0, reducerHandlers_1.duplicateMultipleTasks)(state, action);
        case cellConstants_1.UPDATE_CELL_LABELS:
            return __assign(__assign({}, state), { labels: action.labels, taskGroups: state.taskGroups.map(function (taskGroup) { return (__assign(__assign({}, taskGroup), { tasks: (0, reducerHandlers_1.recursivelyApplyLabelChangesToTask)(taskGroup.tasks, action) })); }) });
        case "CHANGE_GANTT_DATE_RANGE_SUCCESS":
            return __assign(__assign({}, state), { views: state.views.map(function (view) {
                    return view.id === action.boardViewId
                        ? __assign(__assign({}, view), { dateRangeColumnId: action.dateRangeColumnId }) : view;
                }), loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case "GET_GANTT_DATE_RANGE_SUCCESS": {
            var columns = __spreadArray([], state.columns, true);
            if (!columns.some(function (c) { return c.id === action.dateRangeColumn.id; })) {
                columns.splice(action.dateRangeColumn.index, 0, action.dateRangeColumn);
            }
            return __assign(__assign({}, state), { views: state.views.map(function (view) {
                    return view.id === action.boardViewId
                        ? __assign(__assign({}, view), { dateRangeColumnId: action.dateRangeColumn.id }) : view;
                }), columns: columns, loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        }
        case "HANDLE_GANTT_CHANGES_SUCCESS":
            return (0, reducerHandlers_1.reduceGanttChanges)(state, action);
        case boardConstants_1.RESET_COLLABORATORS:
            return __assign(__assign({}, state), { collaborators: new Map() });
        case automationConstants_1.INDEX_AUTOMATION_SUCCESS:
            return __assign(__assign({}, state), { automations: action.automations, loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case automationConstants_1.TOGGLE_AUTOMATION_ACTIVE_FAILED:
        case automationConstants_1.TOGGLE_AUTOMATION_ACTIVE_OPTIMISTIC: {
            var automations = state.automations.map(function (automation) {
                if (automation.id === action.automationId) {
                    return __assign(__assign({}, automation), { active: !automation.active });
                }
                return automation;
            });
            return __assign(__assign({}, state), { automations: automations });
        }
        case automationConstants_1.TOGGLE_AUTOMATION_ACTIVE_SUCCESS: {
            var automations = state.automations.map(function (automation) {
                if (automation.id === action.automationId) {
                    return __assign(__assign({}, automation), { active: action.active });
                }
                return automation;
            });
            return __assign(__assign({}, state), { automations: automations });
        }
        case automationConstants_1.STORE_AUTOMATION_SUCCESS:
            return __assign(__assign({}, state), { automations: state.automations.concat(action.automation), loadings: (0, reducerHandlers_1.stopLoading)(state.loadings, action.loadingType) });
        case automationConstants_1.REMOVE_AUTOMATION_OPTIMISTIC:
        case automationConstants_1.REMOVE_AUTOMATION_SUCCESS:
            return __assign(__assign({}, state), { automations: state.automations.filter(function (automation) { return automation.id !== action.automationId; }) });
        case automationConstants_1.REMOVE_AUTOMATION_FAILED:
            return __assign(__assign({}, state), { automations: state.automations
                    .concat(action.automation)
                    .sort(function (a, b) { return b.id - a.id; }) });
        case "DEACTIVATE_AUTOMATION":
            return __assign(__assign({}, state), { automations: state.automations.map(function (automation) {
                    if (automation.id === action.automationId) {
                        automation.active = false;
                    }
                    return automation;
                }) });
        case taskGroupConstants_1.UPDATE_TASK_GROUP_INDEX_SUCCESS:
        case taskConstants_1.UPDATE_TASK_INDEX_SUCCESS:
        case boardConstants_1.BOARD_TOGGLE_COMPLETE_FAILED:
        default:
            return state;
    }
}
exports.default = reducer;
