"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomConnectionLine_1 = require("@components/Workspace/Edge/CustomConnectionLine");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var useCustomHandle_1 = require("./useCustomHandle");
var useMoveEdge_1 = require("./useMoveEdge");
var redux_1 = require("@hooks/redux");
var vanillaEdgeData_1 = require("@components/Workspace/Edge/vanillaEdgeData");
var converters_1 = require("@helpers/colors/converters");
var useEdgeConnect = function (reactFlowContainer, location, id, workspaceTab, mirror, defaultEdge, handleEdgeDeselect, removeAllUpdatingRefference, relationships, defaultEdgeValue, defaultRelationshipType, project, workspaceStepId) {
    var getState = (0, reactflow_1.useStoreApi)().getState;
    var dispatch = (0, redux_1.useAppDispatch)();
    var _a = (0, react_1.useState)(null), updatingEdgeHandle = _a[0], setUpdatingEdgeHandle = _a[1];
    var _b = (0, react_1.useState)(null), sourceNode = _b[0], setSourceNode = _b[1];
    var _c = (0, react_1.useState)(null), updatingEdge = _c[0], setUpdatingEdge = _c[1];
    var onEdgeUpdateStart = (0, react_1.useCallback)(function (_event, edge, handle) {
        setUpdatingEdge(edge);
        (0, useMoveEdge_1.setGlobalMovingEdge)(edge);
        setUpdatingEdgeHandle(handle);
        (0, useMoveEdge_1.setGlobalMovingEdgeHandle)(handle);
        dispatch((0, workspaceActions_websocket_1.userEditEdges)(edge.id));
    }, []);
    var onEdgeUpdate = (0, react_1.useCallback)(function (oldEdge, newConnection) {
        var globalMovingEdge = (0, useMoveEdge_1.getGlobalMovingEdge)();
        var type = globalMovingEdge === null || globalMovingEdge === void 0 ? void 0 : globalMovingEdge.type;
        if (!type)
            return;
        setUpdatingEdge(null);
        (0, useMoveEdge_1.setGlobalMovingEdge)(null);
        var oldConnection = {
            source: oldEdge.source,
            target: oldEdge.target,
            sourceHandle: oldEdge.sourceHandle,
            targetHandle: oldEdge.targetHandle,
        };
        dispatch((0, workspaceActions_websocket_1.updateEdgePosition)(oldEdge.id, newConnection, oldConnection, type));
    }, [updatingEdge]);
    var onConnectStart = (0, react_1.useCallback)(function (_, sNode) {
        setSourceNode(sNode);
        handleEdgeDeselect(true);
        removeAllUpdatingRefference(true);
    }, [removeAllUpdatingRefference, handleEdgeDeselect]);
    var onConnect = (0, react_1.useCallback)(function (data, customHandle) {
        setSourceNode(null);
        if (data.source !== data.target) {
            var source = data.source, target = data.target, sourceHandle = data.sourceHandle, targetHandle = data.targetHandle;
            var defaultRelationship = relationships.find(function (r) { return r.id === (defaultEdge === null || defaultEdge === void 0 ? void 0 : defaultEdge.value); });
            var edge = {
                relationship_id: defaultEdge
                    ? "".concat(defaultEdge.value)
                    : vanillaEdgeData_1.vanillaEdgeData.data.relationshipId,
                relationshipLabel: defaultEdge ? "".concat(defaultEdge.label) : vanillaEdgeData_1.vanillaEdgeData.label,
                relationshipValue: defaultEdgeValue || vanillaEdgeData_1.vanillaEdgeData.data.value,
                relationshipColor: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.color) || vanillaEdgeData_1.vanillaEdgeData.data.color,
                relationshipLabelBackgroundColor: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.labelBackgroundColor) ||
                    (0, converters_1.rgbaStringToRgbaObject)(vanillaEdgeData_1.vanillaEdgeData.labelBgStyle.background),
                relationshipType: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.type) || defaultRelationshipType,
                notes: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.notes) || vanillaEdgeData_1.vanillaEdgeData.data.notes,
                documents: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.documents) || vanillaEdgeData_1.vanillaEdgeData.data.documents,
                showArrow: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.showArrow) || vanillaEdgeData_1.vanillaEdgeData.data.showArrow,
                animatedLine: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.animated) || vanillaEdgeData_1.vanillaEdgeData.data.animated,
                hideValue: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.hideValue) || vanillaEdgeData_1.vanillaEdgeData.data.hideValue,
                hideLabel: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.hideLabel) || vanillaEdgeData_1.vanillaEdgeData.data.hideLabel,
                lineThrough: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.lineThrough) || vanillaEdgeData_1.vanillaEdgeData.data.lineThrough,
                source: source,
                target: target,
                sourceHandle: sourceHandle,
                targetHandle: targetHandle,
                curveScalar: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.curveScalar) || vanillaEdgeData_1.vanillaEdgeData.data.curveScalar,
                offsetBearing: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.offsetBearing) || vanillaEdgeData_1.vanillaEdgeData.data.offsetBearing,
                customHandle: customHandle,
                pathKnots: vanillaEdgeData_1.vanillaEdgeData.data.pathKnots,
                fontSize: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.fontSize) || undefined,
                fontFamily: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.fontFamily) || undefined,
                fontColor: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.fontColor) || undefined,
            };
            dispatch((0, workspaceActions_websocket_1.postEdge)(id, workspaceTab.value, workspaceStepId || null, edge, true, location, mirror()));
        }
    }, [
        relationships,
        defaultEdge,
        defaultEdgeValue,
        workspaceTab.value,
        workspaceStepId,
        id,
        mirror,
    ]);
    var onConnectEnd = (0, react_1.useCallback)(function (event) {
        try {
            dispatch(workspaceActions_http_1.handleConnectEnd);
            if (!reactFlowContainer.current || !sourceNode) {
                return;
            }
            var sourceNodeId = sourceNode.nodeId, sourceHandle = sourceNode.handleId;
            if (!sourceNodeId || !sourceHandle) {
                return;
            }
            var reactFlowBounds = reactFlowContainer.current.getBoundingClientRect();
            var x = ("clientX" in event ? event.clientX : 0) - reactFlowBounds.left;
            var y = ("clientY" in event ? event.clientY : 0) - reactFlowBounds.top;
            var mouseCoordinates = project({
                x: x,
                y: y,
            });
            var _a = getState(), getNodes = _a.getNodes, connectionEndHandle = _a.connectionEndHandle;
            var internalNodes = getNodes();
            var closestNodeObj = (0, CustomConnectionLine_1.getClosestNode)(sourceNodeId, mouseCoordinates, internalNodes);
            if (closestNodeObj &&
                closestNodeObj.node !== null &&
                closestNodeObj.closestPoint !== null) {
                var customHandle = (0, useCustomHandle_1.generateHandle)(closestNodeObj);
                var position = customHandle.position;
                if (updatingEdgeHandle && updatingEdge && !connectionEndHandle) {
                    var oldConnection = {
                        source: updatingEdge.source,
                        target: updatingEdge.target,
                        sourceHandle: updatingEdge.sourceHandle,
                        targetHandle: updatingEdge.targetHandle,
                    };
                    var connection = {
                        source: updatingEdgeHandle === "source"
                            ? updatingEdge.source
                            : closestNodeObj.node.id,
                        target: updatingEdgeHandle === "target"
                            ? updatingEdge.target
                            : closestNodeObj.node.id,
                        sourceHandle: updatingEdgeHandle === "source"
                            ? updatingEdge.sourceHandle
                            : useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID,
                        targetHandle: updatingEdgeHandle === "target"
                            ? updatingEdge.targetHandle
                            : useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID,
                    };
                    var globalMovingEdge = (0, useMoveEdge_1.getGlobalMovingEdge)();
                    var type = globalMovingEdge === null || globalMovingEdge === void 0 ? void 0 : globalMovingEdge.type;
                    if (!type)
                        return;
                    dispatch((0, workspaceActions_websocket_1.updateEdgePosition)(updatingEdge.id, connection, oldConnection, type, customHandle));
                }
                else if (!updatingEdgeHandle && !connectionEndHandle) {
                    onConnect({
                        source: sourceNodeId,
                        target: closestNodeObj.node.id,
                        sourceHandle: sourceHandle,
                        targetHandle: position,
                    }, customHandle);
                }
            }
        }
        finally {
            setUpdatingEdge(null);
            (0, useMoveEdge_1.setGlobalMovingEdge)(null);
            setUpdatingEdgeHandle(null);
            (0, useMoveEdge_1.setGlobalMovingEdgeHandle)(null);
            setSourceNode(null);
        }
    }, [
        project,
        reactFlowContainer,
        sourceNode,
        onConnect,
        updatingEdge,
        updatingEdgeHandle,
    ]);
    return { onConnectStart: onConnectStart, onConnect: onConnect, onConnectEnd: onConnectEnd, onEdgeUpdateStart: onEdgeUpdateStart, onEdgeUpdate: onEdgeUpdate };
};
exports.default = useEdgeConnect;
