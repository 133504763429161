"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LAWSUIT_CVR_SUCCESS = exports.GET_LAWSUIT_CVR_LOADING = exports.LAWSUIT_DUPLICATE_FAILED = exports.LAWSUIT_DUPLICATE_SUCCESS = exports.LAWSUIT_DUPLICATE_LOADING = exports.GET_LAWSUIT_DROPDOWN_FAILED = exports.GET_LAWSUIT_DROPDOWN_SUCCESS = exports.ADD_LAWSUIT_USERS = exports.CASE_IS_FINISHED_CHANGE = exports.CASE_TYPE_CHANGE = exports.CASE_VALUE_CHANGE = exports.DESCRIPTION_CHANGE = exports.CASE_NUMBER_CHANGE = exports.TITLE_CHANGE = exports.DELETE_LAWSUIT_FAILED = exports.DELETE_LAWSUIT_SUCCESS = exports.PUT_LAWSUIT_FAILED = exports.PUT_LAWSUIT_SUCCESS = exports.SHOW_GROUP_LITE_SUCCESS = exports.SHOW_LAWSUIT_LITE_FAILED = exports.SHOW_LAWSUIT_LITE_SUCCESS = exports.SHOW_LAWSUIT_FAILED = exports.SHOW_LAWSUIT_SUCCESS = exports.SHOW_LAWSUIT_LOADING = exports.POST_LAWSUIT_FAILED = exports.POST_LAWSUIT_SUCCESS = exports.POST_LAWSUIT_LOADING = exports.CREATE_NEW_LAWSUIT = exports.CREATEABLE_LAWSUIT_FAILED = exports.CREATEABLE_LAWSUIT_SUCCESS = exports.CREATEABLE_LAWSUIT_LOADING = exports.CLEAR_MENTIONED_ELEMENTS = exports.GET_RECENT_ELEMENTS_FAILED = exports.GET_RECENT_ELEMENTS_SUCCESS = exports.GET_RECENT_ELEMENTS_LOADING = exports.GET_SHARED_EXTERNAL_ELEMENTS_FAILED = exports.GET_SHARED_EXTERNAL_ELEMENTS_SUCCESS = exports.GET_SHARED_EXTERNAL_ELEMENTS_LOADING = exports.GET_MENTIONED_ELEMENTS_FAILED = exports.GET_MENTIONED_ELEMENTS_SUCCESS = exports.GET_MENTIONED_ELEMENTS_LOADING = exports.CLEAR_RECOMMENDED_EVENTS = exports.GET_RECOMMENDED_EVENTS_FAILED = exports.GET_RECOMMENDED_EVENTS_SUCCESS = exports.GET_RECOMMENDED_EVENTS_LOADING = exports.CLEAR_LAWSUIT_DROPDOWNS = exports.CLEAR_LAWSUITS = exports.GET_LAWSUITS_FAILED = exports.GET_LAWSUITS_SUCCESS = exports.GET_LAWSUITS_LOADING = void 0;
exports.SELECT_IMANAGE_WORKSPACE = exports.CHANGE_LAWSUIT_CLIENT = exports.DELETE_LAWSUIT_CLIENT_FAILED = exports.DELETE_LAWSUIT_CLIENT_SUCCESS = exports.DELETE_LAWSUIT_CLIENT_LOADING = exports.PUT_LAWSUIT_CLIENT_FAILED = exports.PUT_LAWSUIT_CLIENT_SUCCESS = exports.PUT_LAWSUIT_CLIENT_LOADING = exports.POST_LAWSUIT_CLIENT_FAILED = exports.POST_LAWSUIT_CLIENT_SUCCESS = exports.POST_LAWSUIT_CLIENT_LOADING = exports.GET_LAWSUIT_CLIENT_FAILED = exports.GET_LAWSUIT_CLIENT_SUCCESS = exports.GET_LAWSUIT_CLIENT_LOADING = exports.GET_LAWSUIT_CVR_FAILED = void 0;
exports.GET_LAWSUITS_LOADING = "GET_LAWSUITS_LOADING";
exports.GET_LAWSUITS_SUCCESS = "GET_LAWSUITS_SUCCESS";
exports.GET_LAWSUITS_FAILED = "GET_LAWSUITS_FAILED";
exports.CLEAR_LAWSUITS = "CLEAR_LAWSUITS";
exports.CLEAR_LAWSUIT_DROPDOWNS = "CLEAR_LAWSUIT_DROPDOWNS";
exports.GET_RECOMMENDED_EVENTS_LOADING = "GET_RECOMMENDED_EVENTS_LOADING";
exports.GET_RECOMMENDED_EVENTS_SUCCESS = "GET_RECOMMENDED_EVENTS_SUCCESS";
exports.GET_RECOMMENDED_EVENTS_FAILED = "GET_RECOMMENDED_EVENTS_FAILED";
exports.CLEAR_RECOMMENDED_EVENTS = "CLEAR_RECOMMENDED_EVENTS";
exports.GET_MENTIONED_ELEMENTS_LOADING = "GET_MENTIONED_ELEMENTS_LOADING";
exports.GET_MENTIONED_ELEMENTS_SUCCESS = "GET_MENTIONED_ELEMENTS_SUCCESS";
exports.GET_MENTIONED_ELEMENTS_FAILED = "GET_MENTIONED_ELEMENTS_FAILED";
exports.GET_SHARED_EXTERNAL_ELEMENTS_LOADING = "SHARED_EXTERNAL_ELEMENTS_LOADING";
exports.GET_SHARED_EXTERNAL_ELEMENTS_SUCCESS = "SHARED_EXTERNAL_ELEMENTS_SUCCESS";
exports.GET_SHARED_EXTERNAL_ELEMENTS_FAILED = "SHARED_EXTERNAL_ELEMENTS_FAILED";
exports.GET_RECENT_ELEMENTS_LOADING = "GET_RECENT_ELEMENTS_LOADING";
exports.GET_RECENT_ELEMENTS_SUCCESS = "GET_RECENT_ELEMENTS_SUCCESS";
exports.GET_RECENT_ELEMENTS_FAILED = "GET_RECENT_ELEMENTS_FAILED";
exports.CLEAR_MENTIONED_ELEMENTS = "CLEAR_MENTIONED_ELEMENTS";
exports.CREATEABLE_LAWSUIT_LOADING = "CREATEABLE_LAWSUIT_LOADING";
exports.CREATEABLE_LAWSUIT_SUCCESS = "CREATEABLE_LAWSUIT_SUCCESS";
exports.CREATEABLE_LAWSUIT_FAILED = "CREATEABLE_LAWSUIT_FAILED";
exports.CREATE_NEW_LAWSUIT = "CREATE_NEW_LAWSUIT";
exports.POST_LAWSUIT_LOADING = "POST_LAWSUIT_LOADING";
exports.POST_LAWSUIT_SUCCESS = "POST_LAWSUIT_SUCCESS";
exports.POST_LAWSUIT_FAILED = "POST_LAWSUIT_FAILED";
exports.SHOW_LAWSUIT_LOADING = "SHOW_LAWSUIT_LOADING";
exports.SHOW_LAWSUIT_SUCCESS = "SHOW_LAWSUIT_SUCCESS";
exports.SHOW_LAWSUIT_FAILED = "SHOW_LAWSUIT_FAILED";
exports.SHOW_LAWSUIT_LITE_SUCCESS = "SHOW_LAWSUIT_LITE_SUCCESS";
exports.SHOW_LAWSUIT_LITE_FAILED = "SHOW_LAWSUIT_LITE_FAILED";
exports.SHOW_GROUP_LITE_SUCCESS = "SHOW_GROUP_LITE_SUCCESS";
exports.PUT_LAWSUIT_SUCCESS = "PUT_LAWSUIT_SUCCESS";
exports.PUT_LAWSUIT_FAILED = "PUT_LAWSUIT_FAILED";
exports.DELETE_LAWSUIT_SUCCESS = "DELETE_LAWSUIT_SUCCESS";
exports.DELETE_LAWSUIT_FAILED = "DELETE_LAWSUIT_FAILED";
exports.TITLE_CHANGE = "LAWSUIT_TITLE_CHANGE";
exports.CASE_NUMBER_CHANGE = "LAWSUIT_CASE_NUMBER_CHANGE";
exports.DESCRIPTION_CHANGE = "LAWSUIT_DESCRIPTION_CHANGE";
exports.CASE_VALUE_CHANGE = "LAWSUIT_CASE_VALUE_CHANGE";
exports.CASE_TYPE_CHANGE = "LAWSUIT_CASE_TYPE_CHANGE";
exports.CASE_IS_FINISHED_CHANGE = "LAWSUIT_CASE_IS_FINISHED_CHANGE";
exports.ADD_LAWSUIT_USERS = "ADD_LAWSUIT_USERS";
exports.GET_LAWSUIT_DROPDOWN_SUCCESS = "GET_LAWSUIT_DROPDOWN_SUCCESS";
exports.GET_LAWSUIT_DROPDOWN_FAILED = "GET_LAWSUIT_DROPDOWN_FAILED";
exports.LAWSUIT_DUPLICATE_LOADING = "LAWSUIT_DUPLICATE_LOADING";
exports.LAWSUIT_DUPLICATE_SUCCESS = "LAWSUIT_DUPLICATE_SUCCESS";
exports.LAWSUIT_DUPLICATE_FAILED = "LAWSUIT_DUPLICATE_FAILED";
exports.GET_LAWSUIT_CVR_LOADING = "GET_LAWSUIT_CVR_LOADING";
exports.GET_LAWSUIT_CVR_SUCCESS = "GET_LAWSUIT_CVR_SUCCESS";
exports.GET_LAWSUIT_CVR_FAILED = "GET_LAWSUIT_CVR_FAILED";
exports.GET_LAWSUIT_CLIENT_LOADING = "GET_LAWSUIT_CLIENT_LOADING";
exports.GET_LAWSUIT_CLIENT_SUCCESS = "GET_LAWSUIT_CLIENT_SUCCESS";
exports.GET_LAWSUIT_CLIENT_FAILED = "GET_LAWSUIT_CLIENT_FAILED";
exports.POST_LAWSUIT_CLIENT_LOADING = "POST_LAWSUIT_CLIENT_LOADING";
exports.POST_LAWSUIT_CLIENT_SUCCESS = "POST_LAWSUIT_CLIENT_SUCCESS";
exports.POST_LAWSUIT_CLIENT_FAILED = "POST_LAWSUIT_CLIENT_FAILED";
exports.PUT_LAWSUIT_CLIENT_LOADING = "PUT_LAWSUIT_CLIENT_LOADING";
exports.PUT_LAWSUIT_CLIENT_SUCCESS = "PUT_LAWSUIT_CLIENT_SUCCESS";
exports.PUT_LAWSUIT_CLIENT_FAILED = "PUT_LAWSUIT_CLIENT_FAILED";
exports.DELETE_LAWSUIT_CLIENT_LOADING = "DELETE_LAWSUIT_CLIENT_LOADING";
exports.DELETE_LAWSUIT_CLIENT_SUCCESS = "DELETE_LAWSUIT_CLIENT_SUCCESS";
exports.DELETE_LAWSUIT_CLIENT_FAILED = "DELETE_LAWSUIT_CLIENT_FAILED";
exports.CHANGE_LAWSUIT_CLIENT = "CHANGE_LAWSUIT_CLIENT";
exports.SELECT_IMANAGE_WORKSPACE = "SELECT_IMANAGE_WORKSPACE";
exports.GET_SHARED_EXTERNAL_ELEMENTS_SUCCESS;
exports.GET_RECENT_ELEMENTS_SUCCESS;
