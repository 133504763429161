"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_hotkeys_hook_1 = require("react-hotkeys-hook");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var selectedNodesCallback = function (nodes) {
    return nodes
        .filter(function (node) { return node.selected; })
        .map(function (node) { return ({
        id: node.id,
        type: node.type,
    }); });
};
function useWorkspaceHotKeys(setShowContextMenu, handleVisabilityChange, setSnapToGrid, rfInstance, contextNode, handleShowNodeRelations, getAddressData, handleOpenCvr, toggleHelperLines, dispatch) {
    // indsæt element
    (0, react_hotkeys_hook_1.useHotkeys)(["cmd+i", "ctrl+i"], function () {
        setShowContextMenu(false);
    });
    // open company data modal
    (0, react_hotkeys_hook_1.useHotkeys)("alt+c", handleOpenCvr, {}, [handleOpenCvr]);
    // show lines
    (0, react_hotkeys_hook_1.useHotkeys)("alt+g", handleVisabilityChange, {}, [handleVisabilityChange]);
    // snap
    (0, react_hotkeys_hook_1.useHotkeys)("alt+k", function () {
        setSnapToGrid(function (prevVal) { return !prevVal; });
    });
    // helperLines
    (0, react_hotkeys_hook_1.useHotkeys)("alt+h", function () {
        toggleHelperLines();
    });
    // fit to view
    (0, react_hotkeys_hook_1.useHotkeys)("alt+1", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
    }, {}, [rfInstance]);
    // zoom to 100
    (0, react_hotkeys_hook_1.useHotkeys)("alt+0", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomTo(1);
    }, {}, [rfInstance]);
    // zoom out
    (0, react_hotkeys_hook_1.useHotkeys)("alt+-", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomOut();
    }, {}, [rfInstance]);
    // zoom in
    (0, react_hotkeys_hook_1.useHotkeys)("alt-*", function (e) {
        if (e.key === "±" || e.key === "+") {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomIn();
        }
    }, { combinationKey: "-" }, [rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+r", function () {
        contextNode && handleShowNodeRelations(contextNode);
    }, {}, [contextNode, handleShowNodeRelations]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+e", function () {
        contextNode && getAddressData(contextNode.id);
    }, {}, [contextNode]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+w", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.bringToFront)(selectedNodes));
    }, [dispatch, rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+s", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.sendToBack)(selectedNodes));
    }, [dispatch, rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+q", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.bringForward)(selectedNodes));
    }, [dispatch, rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+a", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.sendBackward)(selectedNodes));
    }, [dispatch, rfInstance]);
}
exports.default = useWorkspaceHotKeys;
