"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var redux_1 = require("@hooks/redux");
var constants_2 = require("@pages/Workspaces/constants");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var reactflow_1 = require("reactflow");
var X_SLIDE_PADDING_IN_PX = 30;
var Y_SLIDE_PADDING_IN_PX = 30;
var PPI = 96;
var inch2px = function (inch) { return inch * PPI; };
var CUSTOMER_SLIDE_PROPERTIES = (_a = {},
    _a[constants_1.CUSTOMER.PWC] = {
        slideWidth: 11,
        slideHeight: 8.5,
    },
    _a[constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.BIRD_BIRD] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.ROESGAARD] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.MOALEM] = {
        slideWidth: 10.83,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.ALLEN_OVERY] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a.default = {
        slideWidth: 10,
        slideHeight: 5.63,
    },
    _a);
var getSlideDimensionsInPx = function (customer) {
    var _a;
    var slideDimensions = CUSTOMER_SLIDE_PROPERTIES.default;
    if (customer) {
        // @ts-ignore
        slideDimensions =
            // @ts-ignore
            (_a = CUSTOMER_SLIDE_PROPERTIES[customer]) !== null && _a !== void 0 ? _a : CUSTOMER_SLIDE_PROPERTIES.default;
    }
    return {
        h: inch2px(slideDimensions.slideHeight),
        w: inch2px(slideDimensions.slideWidth),
    };
};
var usePowerPointFitter = function (mirror) {
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var customer = (0, constants_1.getCustomer)(user);
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var fitSelectedElements = (0, react_1.useCallback)(function () {
        var nodeElements = rfInstance.getNodes();
        var selectedNodes = nodeElements.filter(function (node) { return node.selected; });
        var edgeElements = rfInstance.getEdges();
        var selectedEdges = edgeElements.filter(function (edge) { return edge.selected; });
        var layoutedElements = (0, constants_2.getLayoutedElements)(selectedNodes, selectedEdges);
        var _a = getSlideDimensionsInPx(customer), h = _a.h, w = _a.w;
        var _b = layoutedElements.nodes.reduce(function (acc, node) {
            var _a = node.position, x = _a.x, y = _a.y;
            var _b = node.data, width = _b.width, height = _b.height;
            acc.mostLeftX = Math.min(acc.mostLeftX, x);
            acc.mostTopY = Math.min(acc.mostTopY, y);
            acc.mostRightX = Math.max(acc.mostRightX, x + width);
            acc.mostBottomY = Math.max(acc.mostBottomY, y + height);
            return acc;
        }, {
            mostLeftX: Infinity,
            mostTopY: Infinity,
            mostRightX: -Infinity,
            mostBottomY: -Infinity,
        }), mostLeftX = _b.mostLeftX, mostTopY = _b.mostTopY, mostRightX = _b.mostRightX, mostBottomY = _b.mostBottomY;
        var nodeWidthSpan = mostRightX - mostLeftX;
        var nodeHeightSpan = mostBottomY - mostTopY;
        var nodeWidthRatio = (w - X_SLIDE_PADDING_IN_PX) / nodeWidthSpan;
        var nodeHeightRatio = (h - Y_SLIDE_PADDING_IN_PX) / nodeHeightSpan;
        var shouldScaleDown = nodeWidthRatio < 1 || nodeHeightRatio < 1;
        // largest ratio is the one that should be used
        var ratio = Math.min(nodeWidthRatio, nodeHeightRatio);
        var paddingX = X_SLIDE_PADDING_IN_PX;
        var paddingY = Y_SLIDE_PADDING_IN_PX;
        if (shouldScaleDown) {
            nodeWidthSpan *= ratio;
            nodeHeightSpan *= ratio;
        }
        if (nodeWidthRatio >= 1) {
            paddingX = w - nodeWidthSpan;
        }
        if (nodeHeightRatio >= 1) {
            paddingY = h - nodeHeightSpan;
        }
        var nodeChanges = [];
        var changedEdges = new Set();
        var edgeFontSize = 10;
        layoutedElements.nodes.forEach(function (node) {
            // if the nodes are larger than the slide, scale them down
            if (shouldScaleDown) {
                node.position.x = (node.position.x - mostLeftX) * ratio + paddingX / 2;
                node.position.y = (node.position.y - mostTopY) * ratio + paddingY / 2;
                node.data.width *= ratio;
                node.data.height *= ratio;
                if (node.data.fontSize) {
                    node.data.fontSize *= ratio;
                }
                nodeChanges.push({
                    id: node.id,
                    type: "dimensions",
                    dimensions: { width: node.data.width, height: node.data.height },
                    updateStyle: true,
                });
            }
            else {
                // fit the nodes to the slide without scaling
                node.position.x = node.position.x - mostLeftX + paddingX / 2;
                node.position.y = node.position.y - mostTopY + paddingY / 2;
            }
            nodeChanges.push({
                id: node.id,
                type: "position",
                position: { x: node.position.x, y: node.position.y },
            });
        });
        if (shouldScaleDown) {
            layoutedElements.edges.forEach(function (edge) {
                if (edge.data && edge.labelStyle && edge.labelStyle.fontSize) {
                    edgeFontSize = edge.labelStyle.fontSize * ratio;
                    edge.labelStyle.fontSize = edgeFontSize;
                    changedEdges.add(edge.id);
                }
            });
            rfInstance.setEdges(function (edges) {
                return edges.map(function (edge) {
                    if (changedEdges.has(edge.id)) {
                        return __assign(__assign({}, edge), { labelStyle: __assign(__assign({}, edge.labelStyle), { fontSize: edgeFontSize }) });
                    }
                    return edge;
                });
            });
        }
        rfInstance.setNodes(function (nodes) { return (0, reactflow_1.applyNodeChanges)(nodeChanges, nodes); });
        setTimeout(function () {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
        }, 100);
        dispatch((0, workspaceActions_websocket_1.saveAutoLayout)(layoutedElements.nodes, layoutedElements.edges, mirror()));
    }, [rfInstance, customer, dispatch, mirror, location]);
    return { fitSelectedElements: fitSelectedElements };
};
exports.default = usePowerPointFitter;
