"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET_LOADINGS = exports.IMANAGE_GET_WORKSPACE_NOT_FOUND = exports.IMANAGE_GET_WORKSPACE_FAILED = exports.IMANAGE_GET_WORKSPACE_LOADING = exports.IMANAGE_GET_WORKSPACE_SUCCESS = exports.IMANAGE_GET_FOLDER_CHILDREN_FAILED = exports.IMANAGE_GET_FOLDER_CHILDREN_LOADING = exports.IMANAGE_GET_FOLDER_CHILDREN_SUCCESS = exports.IMANAGE_GET_WORKSPACE_CHILDREN_FAILED = exports.IMANAGE_GET_WORKSPACE_CHILDREN_LOADING = exports.IMANAGE_GET_WORKSPACE_CHILDREN_SUCCESS = exports.MY_MATTER_GET_CATEGORY_CHILDREN_FAILED = exports.MY_MATTER_GET_CATEGORY_CHILDREN_LOADING = exports.MY_MATTER_GET_CATEGORY_CHILDREN_SUCCESS = exports.MY_MATTER_GET_FOLDER_CHILDREN_FAILED = exports.MY_MATTER_GET_FOLDER_CHILDREN_LOADING = exports.MY_MATTER_GET_FOLDER_CHILDREN_SUCCESS = exports.MY_MATTER_GET_WORKSPACE_CHILDREN_FAILED = exports.MY_MATTER_GET_WORKSPACE_CHILDREN_LOADING = exports.MY_MATTER_GET_WORKSPACE_CHILDREN_SUCCESS = exports.IMANAGE_GET_MATTER_FAILED = exports.IMANAGE_GET_MATTER_LOADING = exports.IMANAGE_GET_MATTER_SUCCESS = exports.IMANAGE_AUTHENTICATE_FAILED = exports.IMANAGE_AUTHENTICATE_LOADING = exports.IMANAGE_AUTHENTICATE_SUCCESS = exports.SEARCH_IMANAGE_DOCUMENTS_FAILED = exports.SEARCH_IMANAGE_DOCUMENTS_LOADING = exports.SEARCH_IMANAGE_DOCUMENTS_SUCCESS = exports.GET_IMANAGE_DISCOVERY_FAILED = exports.GET_IMANAGE_DISCOVERY_LOADING = exports.GET_IMANAGE_DISCOVERY_SUCCESS = void 0;
exports.GET_IMANAGE_DISCOVERY_SUCCESS = "GET_IMANAGE_DISCOVERY_SUCCESS";
exports.GET_IMANAGE_DISCOVERY_LOADING = "GET_IMANAGE_DISCOVERY_LOADING";
exports.GET_IMANAGE_DISCOVERY_FAILED = "GET_IMANAGE_DISCOVERY_FAILED";
exports.SEARCH_IMANAGE_DOCUMENTS_SUCCESS = "SEARCH_IMANAGE_DOCUMENTS_SUCCESS";
exports.SEARCH_IMANAGE_DOCUMENTS_LOADING = "SEARCH_IMANAGE_DOCUMENTS_LOADING";
exports.SEARCH_IMANAGE_DOCUMENTS_FAILED = "SEARCH_IMANAGE_DOCUMENTS_FAILED";
exports.IMANAGE_AUTHENTICATE_SUCCESS = "IMANAGE_AUTHENTICATE_SUCCESS";
exports.IMANAGE_AUTHENTICATE_LOADING = "IMANAGE_AUTHENTICATE_LOADING";
exports.IMANAGE_AUTHENTICATE_FAILED = "IMANAGE_AUTHENTICATE_FAILED";
exports.IMANAGE_GET_MATTER_SUCCESS = "IMANAGE_GET_MATTER_SUCCESS";
exports.IMANAGE_GET_MATTER_LOADING = "IMANAGE_GET_MATTER_LOADING";
exports.IMANAGE_GET_MATTER_FAILED = "IMANAGE_GET_MATTER_FAILED";
exports.MY_MATTER_GET_WORKSPACE_CHILDREN_SUCCESS = "MY_MATTER_GET_WORKSPACE_CHILDREN_SUCCESS";
exports.MY_MATTER_GET_WORKSPACE_CHILDREN_LOADING = "MY_MATTER_GET_WORKSPACE_CHILDREN_LOADING";
exports.MY_MATTER_GET_WORKSPACE_CHILDREN_FAILED = "MY_MATTER_GET_WORKSPACE_CHILDREN_FAILED";
exports.MY_MATTER_GET_FOLDER_CHILDREN_SUCCESS = "MY_MATTER_GET_FOLDER_CHILDREN_SUCCESS";
exports.MY_MATTER_GET_FOLDER_CHILDREN_LOADING = "MY_MATTER_GET_FOLDER_CHILDREN_LOADING";
exports.MY_MATTER_GET_FOLDER_CHILDREN_FAILED = "MY_MATTER_GET_FOLDER_CHILDREN_FAILED";
exports.MY_MATTER_GET_CATEGORY_CHILDREN_SUCCESS = "MY_MATTER_GET_CATEGORY_CHILDREN_SUCCESS";
exports.MY_MATTER_GET_CATEGORY_CHILDREN_LOADING = "MY_MATTER_GET_CATEGORY_CHILDREN_LOADING";
exports.MY_MATTER_GET_CATEGORY_CHILDREN_FAILED = "MY_MATTER_GET_CATEGORY_CHILDREN_FAILED";
exports.IMANAGE_GET_WORKSPACE_CHILDREN_SUCCESS = "IMANAGE_GET_WORKSPACE_CHILDREN_SUCCESS";
exports.IMANAGE_GET_WORKSPACE_CHILDREN_LOADING = "IMANAGE_GET_WORKSPACE_CHILDREN_LOADING";
exports.IMANAGE_GET_WORKSPACE_CHILDREN_FAILED = "IMANAGE_GET_WORKSPACE_CHILDREN_FAILED";
exports.IMANAGE_GET_FOLDER_CHILDREN_SUCCESS = "IMANAGE_GET_FOLDER_CHILDREN_SUCCESS";
exports.IMANAGE_GET_FOLDER_CHILDREN_LOADING = "IMANAGE_GET_FOLDER_CHILDREN_LOADING";
exports.IMANAGE_GET_FOLDER_CHILDREN_FAILED = "IMANAGE_GET_FOLDER_CHILDREN_FAILED";
exports.IMANAGE_GET_WORKSPACE_SUCCESS = "IMANAGE_GET_WORKSPACE_SUCCESS";
exports.IMANAGE_GET_WORKSPACE_LOADING = "IMANAGE_GET_WORKSPACE_LOADING";
exports.IMANAGE_GET_WORKSPACE_FAILED = "IMANAGE_GET_WORKSPACE_FAILED";
exports.IMANAGE_GET_WORKSPACE_NOT_FOUND = "IMANAGE_GET_WORKSPACE_NOT_FOUND";
exports.RESET_LOADINGS = "RESET_LOADINGS";
