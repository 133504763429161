"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var data_1 = require("@customTypes/data");
var lawsuits_1 = require("@customTypes/reducers/lawsuits");
var folderConstants_1 = require("@redux/constants/folderConstants");
var workspaceConstants_1 = require("./workspaceConstants");
var lodash_1 = require("lodash");
var nodeEditable_1 = require("@helpers/select/nodeEditable");
var workspaceReducerFunctions_1 = __importStar(require("./workspaceReducerFunctions"));
var useCustomHandle_1 = require("@hooks/workspace/useCustomHandle");
var reactFlow_1 = require("@customTypes/reactFlow");
/* eslint-disable @typescript-eslint/no-empty-function */
var initialState = {
    loading: false,
    initialLoading: false,
    initialLoadingCvr: false,
    elementsLoading: false,
    workspaces: [],
    recentWorkspaces: [],
    recentLoading: false,
    workspaceId: null,
    label: "",
    description: "",
    groupOrLawsuit: lawsuits_1.INITIALLAWSUIT,
    signed: false,
    signedBy: undefined,
    editable: false,
    nodeElements: [],
    edgeElements: [],
    attributesDropDownOptions: [],
    relationships: [],
    nodes: new Map(),
    handleVisability: true,
    zoom: 0,
    xPosition: 0,
    yPosition: 0,
    outputs: [],
    companyData: null,
    publicAuthenticated: false,
    publicUserFirstName: "",
    publicUserLastName: "",
    publicAuthenticatedId: null,
    revisionHistory: null,
    hasVisitedPublic: false,
    addressInfo: {
        "Matrikelnr.": "",
        Ejerlav: "",
        Kommune: "",
        "Antal bygninger": 0,
        Addresse: "",
    },
    showAddressInfo: false,
    runIntro: true,
    specificWorkspaceTags: [],
    showInternationalDisclaimer: true,
    mouseLoading: false,
    undo: {
        isPossible: false,
        stack: [],
    },
    redo: {
        isPossible: false,
        stack: [],
    },
    nodeStickyUndoEvent: false,
    framePanelOpen: false,
    workspaceTab: __assign(__assign({}, data_1.initialSelectOptionWithIndex), { makeStepFrames: false }),
    workspaceTabs: [],
    workspaceTabLoading: false,
    connecting: false,
    reportPanelOpen: false,
    reportPanelLoading: false,
    reportPanelViewingFor: null,
    reportPanelViewingForLoading: false,
    drilldownNodes: [],
    drilldownLoading: {},
    mergeFieldsOpen: false,
    mergeFields: [],
    mergeLoading: false,
    duplicateLoading: false,
    alertPanelOpen: false,
    customRulesAlerts: false,
    collaborators: new Map(),
    room: "",
    showDocumentModal: false,
    infoModalData: null,
    defaultRelationship: {
        relationship: null,
        value: "",
    },
    documentsLoading: false,
    legendLoading: false,
    legend: [],
    legendIdLoading: null,
    isUndoingOrRedoing: false,
    isPublic: false,
    aiLoading: false,
    files: [],
    isFileUploadLoading: false,
    unsettledLabels: [],
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case workspaceConstants_1.GET_WORKSPACES_LOADING:
            return __assign(__assign({}, state), { loading: true, framePanelOpen: false });
        case workspaceConstants_1.WORKSPACE_PUT_NODE_LOADING:
        case workspaceConstants_1.WORKSPACE_PUT_MULTIPLE_NODES_LOADING:
        case workspaceConstants_1.POST_EDGE_LOADING:
        case workspaceConstants_1.PUT_EDGE_LOADING:
        case workspaceConstants_1.SAVE_UNSETTLED_LABEL_SELECTIONS_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case workspaceConstants_1.IMPORT_FILES_ON_NODE_LOADING:
            return __assign(__assign({}, state), { documentsLoading: true });
        case workspaceConstants_1.GET_WORKSPACES_SUCCESS:
            return __assign(__assign({}, state), { workspaces: action.workspaces, loading: false });
        case workspaceConstants_1.GET_WORKSPACES_FAILED:
        case workspaceConstants_1.POST_EDGE_FAILED:
        case workspaceConstants_1.PUT_EDGE_FAILED:
        case workspaceConstants_1.WORKSPACE_PUT_NODE_FAILED:
        case workspaceConstants_1.WORKSPACE_PUT_MULTIPLE_NODES_FAILED:
        case workspaceConstants_1.ANALYSE_OUTPUT_FAILED:
        case workspaceConstants_1.IMPORT_FILES_ON_NODE_FAILED:
            return __assign(__assign({}, state), { documentsLoading: false });
        case workspaceConstants_1.WORKSPACE_ADD_ELEMENTS_LOADING:
        case workspaceConstants_1.WORKSPACE_PUT_STICKY_LOADING:
        case workspaceConstants_1.SOFT_DELETE_WORKSPACE_NODES_LOADING:
        case workspaceConstants_1.SOFT_DELETE_WORKSPACE_EDGES_LOADING:
        case workspaceConstants_1.SAVE_NODE_POSITION_LOADING:
        case workspaceConstants_1.HARD_DELETE_WORKSPACE_ELEMENTS_LOADING:
        case workspaceConstants_1.POST_STANDARD_NODE_LOADING:
        case workspaceConstants_1.FIT_SIZE_TO_LABEL_LOADING:
        case workspaceConstants_1.EDGE_LABEL_MOVE_LOADING:
        case workspaceConstants_1.WORKSPACE_POST_FILE_LOADING:
        case workspaceConstants_1.ALIGN_ELEMENTS_LOADING:
        case workspaceConstants_1.EDGE_PATH_DRAG_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.WORKSPACE_ADD_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: false, nodeElements: __spreadArray(__spreadArray([], state.nodeElements, true), action.nodes, true), edgeElements: __spreadArray(__spreadArray([], state.edgeElements, true), action.edges, true) });
        case workspaceConstants_1.WORKSPACE_ADD_ELEMENTS_FAILED:
        case workspaceConstants_1.FIT_SIZE_TO_LABEL_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.CREATE_WORKSPACE:
            return __assign(__assign({}, state), { label: "", description: "", groupOrLawsuit: lawsuits_1.INITIALLAWSUIT, workspaceId: null, specificWorkspaceTags: [], nodeElements: [], edgeElements: [] });
        case workspaceConstants_1.POST_WORKSPACE_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case workspaceConstants_1.POST_WORKSPACE_SUCCESS:
            return __assign(__assign({}, state), { workspaceId: action.id, workspaceTab: action.tab, unsettledLabels: action.unsettledLabels, loading: false });
        case workspaceConstants_1.POST_WORKSPACE_FAILED:
        case workspaceConstants_1.PUT_WORKSPACE_FAILED:
        case workspaceConstants_1.DELETE_WORKSPACE_SUCCESS:
        case workspaceConstants_1.DELETE_WORKSPACE_FAILED:
        case workspaceConstants_1.GET_ATTRIBUTE_DROPDOWN_FAILED:
        case workspaceConstants_1.GET_NODE_ATTRIBUTE_DROPDOWN_FAILED:
        case workspaceConstants_1.WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED:
        case workspaceConstants_1.GET_EDITED_ELEMENTS_BY_USERS_FAILED:
        case workspaceConstants_1.SAVE_UNSETTLED_LABEL_SELECTIONS_FAILED:
        case workspaceConstants_1.SAVE_UNSETTLED_LABEL_SELECTIONS_SUCCESS:
            return __assign(__assign({}, state), { loading: false });
        case workspaceConstants_1.SHOW_WORKSPACE_LOADING:
            return __assign(__assign({}, state), { initialLoading: true, nodeElements: [], edgeElements: [], drilldownNodes: [] });
        case workspaceConstants_1.WORKSPACE_NODE_ADD_TO_LIST: {
            var nodes = new Map(state.nodes);
            nodes.set(action.node.id, action.node);
            return __assign(__assign({}, state), { nodes: nodes });
        }
        case workspaceConstants_1.SHOW_WORKSPACE_SUCCESS:
            return __assign(__assign({}, state), { label: action.label, description: action.description, groupOrLawsuit: action.lawsuit, zoom: action.zoom, xPosition: action.x_position, yPosition: action.y_position, signed: action.signed, signedBy: action.signedBy, specificWorkspaceTags: action.tags, initialLoading: false, workspaceTab: action.workspaceTab, workspaceTabs: action.workspaceTabs, customRulesAlerts: action.custom_rules_alerts });
        case workspaceConstants_1.SHOW_WORKSPACE_FAILED:
            return __assign(__assign({}, state), { initialLoading: false });
        case workspaceConstants_1.PUT_Z_INDEX_TO_FRONT_LOADING:
        case workspaceConstants_1.PUT_Z_INDEX_TO_BACK_LOADING:
        case workspaceConstants_1.PUT_Z_INDEX_FORWARD_LOADING:
        case workspaceConstants_1.PUT_Z_INDEX_BACKWARD_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.PUT_Z_INDEX_TO_BACK_SUCCESS:
        case workspaceConstants_1.PUT_Z_INDEX_TO_FRONT_SUCCESS:
        case workspaceConstants_1.PUT_Z_INDEX_FORWARD_SUCCESS:
        case workspaceConstants_1.PUT_Z_INDEX_BACKWARD_SUCCESS: {
            var newNodes = state.nodeElements.slice();
            for (var I = 0; I < newNodes.length; I++) {
                var element = newNodes[I];
                var zIndex = action.items[element.id];
                if (zIndex) {
                    element.style = __assign(__assign({}, element.style), { zIndex: zIndex });
                }
            }
            return __assign(__assign({}, state), { nodeElements: newNodes, mouseLoading: false });
        }
        case workspaceConstants_1.PUT_Z_INDEX_TO_FRONT_FAILED:
        case workspaceConstants_1.PUT_Z_INDEX_TO_BACK_FAILED:
        case workspaceConstants_1.PUT_Z_INDEX_FORWARD_FAILED:
        case workspaceConstants_1.PUT_Z_INDEX_BACKWARD_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_ELEMENTS_LOADING:
            return __assign(__assign({}, state), { elementsLoading: true });
        case workspaceConstants_1.GET_WORKSPACE_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: action.elements.nodes, edgeElements: action.elements.edges, elementsLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_ELEMENTS_FAILED:
            return __assign(__assign({}, state), { elementsLoading: false });
        case workspaceConstants_1.GET_ATTRIBUTE_DROPDOWN_SUCCESS:
        case workspaceConstants_1.GET_NODE_ATTRIBUTE_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { attributesDropDownOptions: action.attributes });
        case workspaceConstants_1.CLEAR_ATTRIBUTE_DROPDOWN:
            return __assign(__assign({}, state), { attributesDropDownOptions: initialState.attributesDropDownOptions });
        case workspaceConstants_1.LABEL_CHANGE:
            return __assign(__assign({}, state), { label: action.label });
        case workspaceConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case workspaceConstants_1.ADD_LAWSUIT:
            return __assign(__assign({}, state), { groupOrLawsuit: action.groupOrLawsuit });
        case workspaceConstants_1.CHANGE_TAGS:
            return __assign(__assign({}, state), { specificWorkspaceTags: action.tags });
        case workspaceConstants_1.POST_EDGE_SUCCESS: {
            var updatedNodes = (0, workspaceReducerFunctions_1.seperateNodes)({
                payloadNodes: action.nodes,
                stateNodes: state.nodeElements,
            }).updatedNodes;
            return __assign(__assign({}, state), { edgeElements: __spreadArray(__spreadArray([], state.edgeElements, true), action.edges, true), nodeElements: updatedNodes, loading: false });
        }
        case workspaceConstants_1.PUT_EDGE_SUCCESS:
            return __assign(__assign({}, state), { edgeElements: __spreadArray(__spreadArray([], state.edgeElements.filter(function (edge) { return !action.edges.map(function (e) { return e.id; }).includes(edge.id); }), true), action.edges, true), nodeElements: state.nodeElements.map(function (node) {
                    node.selected = false;
                    return node;
                }), loading: false });
        case workspaceConstants_1.DELETE_WORKSPACE_EDGES_LOADING:
        case workspaceConstants_1.DELETE_WORKSPACE_NODES_LOADING:
        case workspaceConstants_1.WORKSPACE_POST_NODE_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.DUPLICATE_NODE_ON_EDGE_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.CREATE_DUPLICATE_FROM_HANDLE_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.GET_WORKSPACE_NODE_COMPANY_DATA_LOADING:
            return __assign(__assign({}, state), { loading: true, companyData: null });
        case workspaceConstants_1.PUBLIC_ACCESS_WORKSPACE_LOADING:
        case workspaceConstants_1.SHARE_WORKSPACE_LOADING:
        case workspaceConstants_1.GET_WORKSPACE_NODE_ADDRESS_INFO_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case workspaceConstants_1.ANALYSE_OUTPUT_LOADING:
            return __assign(__assign({}, state), { loading: true, reportPanelLoading: true });
        case workspaceConstants_1.DELETE_WORKSPACE_EDGES_SUCCESS:
        case workspaceConstants_1.DELETE_WORKSPACE_NODES_SUCCESS:
        case workspaceConstants_1.HARD_DELETE_WORKSPACE_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.SAVE_NODE_SIZE_FAILED:
        case workspaceConstants_1.DELETE_WORKSPACE_EDGES_FAILED:
        case workspaceConstants_1.DELETE_WORKSPACE_NODES_FAILED:
        case workspaceConstants_1.WORKSPACE_POST_NODE_FAILED:
        case workspaceConstants_1.WORKSPACE_POST_ICON_FAILED:
        case workspaceConstants_1.MAKE_STEP_FRAMES_ON_TAB_FAILED:
        case workspaceConstants_1.SOFT_DELETE_WORKSPACE_NODES_FAILED:
        case workspaceConstants_1.WORKSPACE_PUT_STICKY_FAILED:
        case workspaceConstants_1.SAVE_NODE_POSITION_FAILED:
        case workspaceConstants_1.HARD_DELETE_WORKSPACE_ELEMENTS_FAILED:
        case workspaceConstants_1.POST_STANDARD_NODE_FAILED:
        case workspaceConstants_1.EDGE_LABEL_MOVE_FAILED:
        case workspaceConstants_1.EDGE_PATH_DRAG_FAILED:
        case workspaceConstants_1.ALIGN_ELEMENTS_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_NODE_COMPANY_DATA_FAILED:
        case workspaceConstants_1.GET_WORKSPACE_NODE_ADDRESS_INFO_FAILED:
        case workspaceConstants_1.SHARE_WORKSPACE_FAILED:
        case workspaceConstants_1.PUBLIC_ACCESS_WORKSPACE_FAILED:
            return __assign(__assign({}, state), { loading: false });
        case workspaceConstants_1.WORKSPACE_POST_NODE_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], state.nodeElements, true), [action.node], false).sort(workspaceReducerFunctions_1.sortZIndex), mouseLoading: false });
        case workspaceConstants_1.SAVE_NODE_SIZE_SUCCESS:
        case workspaceConstants_1.WORKSPACE_PUT_NODE_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) { var _a; return node.id === ((_a = action.node) === null || _a === void 0 ? void 0 : _a.id) ? action.node : node; }), loading: false });
        case workspaceConstants_1.WORKSPACE_PUT_MULTIPLE_NODES_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements
                    .map(function (node) {
                    var newNode = action.payload.nodes.find(function (n) { return n.id === node.id; });
                    if (newNode) {
                        return newNode;
                    }
                    return node;
                })
                    .sort(workspaceReducerFunctions_1.sortZIndex), loading: false });
        case workspaceConstants_1.WORKSPACE_PUT_NODE_COLOR_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) { var _a; return node.id === ((_a = action.node) === null || _a === void 0 ? void 0 : _a.id) ? action.node : node; }), loading: false });
        case workspaceConstants_1.GET_NODE_VALUES_SUCCESS: {
            var nodes_1 = new Map();
            action.nodes.forEach(function (node) {
                nodes_1.set(node.id, node);
            });
            return __assign(__assign({}, state), { nodes: nodes_1 });
        }
        case workspaceConstants_1.GET_RELATIONSHIP_VALUES_SUCCESS:
            return __assign(__assign({}, state), { relationships: action.relationships });
        case workspaceConstants_1.ANALYSE_OUTPUT_SUCCESS:
            return __assign(__assign({}, state), { outputs: action.outputs, loading: false, reportPanelLoading: false });
        case workspaceConstants_1.GET_CVR_NODES_LOADING:
            return __assign(__assign({}, state), { loading: true, initialLoadingCvr: true });
        case workspaceConstants_1.GET_CVR_NODES_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: action.nodes, edgeElements: action.edges, loading: false, initialLoadingCvr: false });
        case workspaceConstants_1.GET_CVR_NODES_FAILED:
            return __assign(__assign({}, state), { loading: false, initialLoadingCvr: false });
        case workspaceConstants_1.LAYOUT_ELEMENTS:
            return __assign(__assign({}, state), { 
                // @ts-ignore
                nodeElements: action.nodes, 
                // @ts-ignore
                edgeElements: action.edges });
        case workspaceConstants_1.GET_WORKSPACE_NODE_COMPANY_DATA_SUCCESS:
            return __assign(__assign({}, state), { companyData: action.companyData, loading: false });
        case workspaceConstants_1.GET_WORKSPACE_NODE_ADDRESS_INFO_SUCCESS:
            return __assign(__assign({}, state), { addressInfo: action.addressInfo, showAddressInfo: true, loading: false });
        case workspaceConstants_1.SHARE_WORKSPACE_SUCCESS:
        case workspaceConstants_1.WORKSPACE_ANALYSIS_SAVE_SUCCESS:
            return __assign(__assign({}, state), { loading: false });
        case workspaceConstants_1.PUBLIC_ACCESS_WORKSPACE_SUCCESS:
            return __assign(__assign({}, state), { publicAuthenticated: true, publicAuthenticatedId: action.workspaceId, publicUserFirstName: action.publicUserFirstName, publicUserLastName: action.publicUserLastName, editable: action.editable, loading: false });
        case workspaceConstants_1.WORKSPACE_ANALYSIS_REVISION_SUCCESS:
            return __assign(__assign({}, state), { revisionHistory: action.revisionHistory, loading: false });
        case workspaceConstants_1.EDGE_ADD_TO_LIST:
            return __assign(__assign({}, state), { relationships: __spreadArray(__spreadArray([], state.relationships, true), [action.edge], false) });
        case workspaceConstants_1.WORKSPACE_NODE_ATTRIBUT_ADD_TO_LIST:
            return __assign(__assign({}, state), { attributesDropDownOptions: __spreadArray(__spreadArray([], state.attributesDropDownOptions, true), [action.attribut], false) });
        case workspaceConstants_1.SHOW_HANDLES_CHANGE:
            return __assign(__assign({}, state), { handleVisability: action.bool });
        case workspaceConstants_1.SET_PUBLIC_ACCESS_FALSE:
            return __assign(__assign({}, state), { publicAuthenticated: false, publicAuthenticatedId: null });
        case workspaceConstants_1.ANALYSIS_TEXT_CHANGE:
            return __assign(__assign({}, state), { outputs: state.outputs.map(function (output, index) {
                    return index === action.index
                        ? __assign(__assign({}, output), { action: __assign(__assign({}, output.action), { output: action.text }) }) : output;
                }) });
        case workspaceConstants_1.GET_NODE_VALUES_FAILED:
        case workspaceConstants_1.GET_RELATIONSHIP_VALUES_FAILED:
        case workspaceConstants_1.WORKSPACE_ANALYSIS_SAVE_FAILED:
        case workspaceConstants_1.WORKSPACE_ANALYSIS_REVISION_FAILED:
        case workspaceConstants_1.WS_CONNECTION_ERROR:
            return state;
        case workspaceConstants_1.WS_VALIDATION_ERROR:
            return __assign({}, state);
        case workspaceConstants_1.PUT_EDGE_POSITION_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.PUT_EDGE_POSITION_SUCCESS: {
            var nodeElements_1 = {};
            state.nodeElements.forEach(function (node) {
                nodeElements_1[node.id] = __assign({}, node);
            });
            (action.nodes || []).forEach(function (node) {
                nodeElements_1[node.id] = node;
            });
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) {
                    return edge.id === action.edge.id ? action.edge : edge;
                }), nodeElements: Object.values(nodeElements_1).sort(workspaceReducerFunctions_1.sortZIndex), mouseLoading: false });
        }
        case workspaceConstants_1.PUT_EDGE_POSITION_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.SET_PUBLIC_VISITED:
            return __assign(__assign({}, state), { hasVisitedPublic: true });
        case workspaceConstants_1.SET_SHOW_ADDRESS_INFO:
            return __assign(__assign({}, state), { showAddressInfo: action.show });
        case workspaceConstants_1.DO_NOT_SHOW_INTERNATIONAL_DISCLAIMER_AGAIN:
            return __assign(__assign({}, state), { showInternationalDisclaimer: false });
        case workspaceConstants_1.STOP_LOADING:
            return __assign(__assign({}, state), { loading: false, initialLoadingCvr: false });
        case workspaceConstants_1.CHANGE_NODES:
            return __assign(__assign({}, state), { nodeElements: action.nodes });
        case workspaceConstants_1.CHANGE_EDGES:
            return __assign(__assign({}, state), { edgeElements: action.edges });
        case workspaceConstants_1.RESET_COMPANY_DATA:
            return __assign(__assign({}, state), { companyData: null });
        case workspaceConstants_1.WORKSPACE_POST_ICON_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.WORKSPACE_POST_ICON_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], state.nodeElements, true), [action.node], false).sort(workspaceReducerFunctions_1.sortZIndex), mouseLoading: false });
        case workspaceConstants_1.TOGGLE_NODE_STICKY_UNDO_EVENT:
            return __assign(__assign({}, state), { nodeStickyUndoEvent: action.bool });
        case workspaceConstants_1.WORKSPACE_PUT_STICKY_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    if (node.id === action.sticky.id) {
                        return action.sticky;
                    }
                    return node;
                }), mouseLoading: false });
        case workspaceConstants_1.POST_DROPDOWN_VALUE_SUCCESS: {
            return __assign(__assign({}, state), { attributesDropDownOptions: __spreadArray(__spreadArray([], state.attributesDropDownOptions, true), [action.attribute], false) });
        }
        case workspaceConstants_1.PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS: {
            var attributesDropDownOptions = __spreadArray([], state.attributesDropDownOptions, true);
            attributesDropDownOptions.map(function (attribute) {
                if (attribute.id === action.attribute.id) {
                    return action.attribute;
                }
                return attribute;
            });
            return __assign(__assign({}, state), { attributesDropDownOptions: attributesDropDownOptions });
        }
        case workspaceConstants_1.POST_STANDARD_NODE_SUCCESS: {
            var nodes = new Map(state.nodes);
            nodes.set(action.node.id, action.node);
            return __assign(__assign({}, state), { nodes: nodes, mouseLoading: false });
        }
        case workspaceConstants_1.TOGGLE_FRAME_PANEL: {
            return __assign(__assign({}, state), { framePanelOpen: !state.framePanelOpen, alertPanelOpen: false, reportPanelOpen: false, reportPanelViewingFor: null, outputs: [] });
        }
        case workspaceConstants_1.OPEN_FRAME_PANEL: {
            return __assign(__assign({}, state), { framePanelOpen: true, alertPanelOpen: false, reportPanelOpen: false });
        }
        case workspaceConstants_1.CLOSE_FRAME_PANEL: {
            return __assign(__assign({}, state), { framePanelOpen: false, alertPanelOpen: false, reportPanelOpen: false });
        }
        case workspaceConstants_1.TOGGLE_REPORT_PANEL: {
            return __assign(__assign({}, state), { reportPanelOpen: !state.reportPanelOpen, alertPanelOpen: false, framePanelOpen: false, reportPanelViewingFor: null, outputs: [] });
        }
        case workspaceConstants_1.OPEN_REPORT_PANEL: {
            return __assign(__assign({}, state), { reportPanelOpen: true, alertPanelOpen: false, framePanelOpen: false });
        }
        case workspaceConstants_1.CLOSE_REPORT_PANEL: {
            return __assign(__assign({}, state), { reportPanelOpen: false, alertPanelOpen: false, framePanelOpen: false });
        }
        case workspaceConstants_1.TOGGLE_ALERT_PANEL:
            return __assign(__assign({}, state), { alertPanelOpen: !state.alertPanelOpen, reportPanelOpen: false, framePanelOpen: false, reportPanelViewingFor: null, outputs: [] });
        case workspaceConstants_1.OPEN_ALERT_PANEL:
            return __assign(__assign({}, state), { alertPanelOpen: true, reportPanelOpen: false, framePanelOpen: false });
        case workspaceConstants_1.CLOSE_ALERT_PANEL:
            return __assign(__assign({}, state), { alertPanelOpen: false, reportPanelOpen: false, framePanelOpen: false });
        case workspaceConstants_1.POST_WORKSPACE_TAB_SUCCESS:
            return __assign(__assign({}, state), { workspaceTabs: __spreadArray(__spreadArray([], state.workspaceTabs, true), [action.tab], false), workspaceTab: action.tab, 
                // @ts-ignore
                edgeElements: action.edges ? action.edges : [], nodeElements: action.nodes ? action.nodes : [] });
        case workspaceConstants_1.PUT_WORKSPACE_TAB_TITLE_SUCCESS:
            return __assign(__assign({}, state), { workspaceTabs: state.workspaceTabs.map(function (tab) {
                    if (tab.value === action.tab.value) {
                        return action.tab;
                    }
                    return tab;
                }), workspaceTab: action.tab });
        case workspaceConstants_1.DELETE_WORKSPACE_TAB_LOADING:
        case workspaceConstants_1.HANDLE_WORKSPACE_TAB_CHANGE_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true, workspaceTabLoading: true });
        case workspaceConstants_1.DELETE_WORKSPACE_TAB_FAILED:
        case workspaceConstants_1.HANDLE_WORKSPACE_TAB_CHANGE_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false, workspaceTabLoading: false });
        case workspaceConstants_1.DELETE_WORKSPACE_TAB_SUCCESS:
            return __assign(__assign({}, state), { workspaceTabs: state.workspaceTabs.filter(function (tab) { return tab.value !== action.removedTab.value; }), workspaceTab: action.tab, nodeElements: action.nodes, edgeElements: action.edges, mouseLoading: false, workspaceTabLoading: false });
        case workspaceConstants_1.HANDLE_WORKSPACE_TAB_CHANGE_SUCCESS:
            return __assign(__assign({}, state), { workspaceTab: state.workspaceTabs.find(function (t) { return t.value === action.tabId; }) || state.workspaceTab, mouseLoading: false, workspaceTabLoading: false, drilldownNodes: [] });
        case workspaceConstants_1.HANDLE_WORKSPACE_TAB_CHANGE:
            return __assign(__assign({}, state), { workspaceTab: state.workspaceTabs.find(function (t) { return t.value === action.tabId; }) || state.workspaceTab, workspaceTabLoading: false, mouseLoading: false });
        case workspaceConstants_1.HANDLE_WORKSPACE_TAB_LABEL_CHANGE:
            return __assign(__assign({}, state), { workspaceTab: __assign(__assign({}, state.workspaceTab), { label: action.label }) });
        case workspaceConstants_1.CHANGE_NODE_DISPLAY_NAME:
            return __assign(__assign({}, state), { 
                // @ts-ignore works
                nodeElements: state.nodeElements.map(function (node) {
                    if (node.id === action.id) {
                        var copy = (0, nodeEditable_1.removeUserEditingFromNode)(node);
                        return __assign(__assign({}, copy), { data: __assign(__assign({}, copy.data), { displayName: action.displayName, height: action.height, width: action.width }), height: action.height, width: action.width });
                    }
                    return node;
                }) });
        case workspaceConstants_1.FIT_SIZE_TO_LABEL_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: initialState.mouseLoading, nodeElements: state.nodeElements.map(function (node) {
                    return node.id === action.id
                        ? __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { fitSizeToLabel: action.fitSizeToLabel, height: action.height, width: action.width }), height: action.height, width: action.width }) : node;
                }) });
        case workspaceConstants_1.CHANGE_NODE_DIMENSIONS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    return node.id === action.id
                        ? __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { height: action.height, width: action.width }), height: action.height, width: action.width }) : node;
                }) });
        case workspaceConstants_1.HANDLE_CONNECT_START:
            return __assign(__assign({}, state), { connecting: true });
        case workspaceConstants_1.HANDLE_CONNECT_END:
            return __assign(__assign({}, state), { connecting: false });
        case workspaceConstants_1.VIEW_REPORT_ELEMENTS_LOADING:
            return __assign(__assign({}, state), { reportPanelViewingForLoading: true });
        case workspaceConstants_1.VIEW_REPORT_ELEMENTS_FAILED:
            return __assign(__assign({}, state), { reportPanelViewingForLoading: false });
        case workspaceConstants_1.VIEW_REPORT_ELEMENTS:
            return __assign(__assign({}, state), { reportPanelViewingForLoading: false, reportPanelViewingFor: action.report
                    ? {
                        id: action.report.action.id,
                        nodeIds: action.report.nodeIds,
                        edgeIds: action.report.edgeIds,
                    }
                    : null });
        case workspaceConstants_1.DISBAND_DRILLDOWN_LOADING:
        case workspaceConstants_1.DRILLDOWN_LOADING:
            return __assign(__assign({}, state), { drilldownLoading: __assign(__assign({}, state.drilldownLoading), (_a = {}, _a[action.drilldownNodeId] = true, _a)) });
        case workspaceConstants_1.DISBAND_DRILLDOWN_FAILED:
        case workspaceConstants_1.DRILLDOWN_FAILED:
            return __assign(__assign({}, state), { drilldownLoading: __assign(__assign({}, state.drilldownLoading), (_b = {}, _b[action.drilldownNodeId] = false, _b)) });
        case workspaceConstants_1.DRILLDOWN_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], state.nodeElements.filter(function (n) { return !action.nodesToHide.includes(n.id); }), true), action.nodesToShow, true).sort(workspaceReducerFunctions_1.sortZIndex), drilldownNodes: action.drilldownAction === "expand"
                    ? __spreadArray(__spreadArray([], state.drilldownNodes, true), [
                        state.nodeElements.find(function (s) { return s.id === action.drilldownNodeId; }),
                    ], false) : state.drilldownNodes.filter(function (s) { return s.id !== action.drilldownNodeId; }), drilldownLoading: __assign(__assign({}, state.drilldownLoading), (_c = {}, _c[action.drilldownNodeId] = false, _c)) });
        case workspaceConstants_1.DISBAND_DRILLDOWN_SUCCESS: {
            var _e = (0, workspaceReducerFunctions_1.seperateNodes)({
                payloadNodes: action.nodesToShow,
                stateNodes: state.nodeElements,
            }), addedNodes = _e.addedNodes, updatedNodes = _e.updatedNodes;
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], updatedNodes.filter(function (n) { return !action.nodesToHide.includes(n.id); }), true), addedNodes, true).sort(workspaceReducerFunctions_1.sortZIndex), drilldownNodes: state.drilldownNodes.filter(function (s) { return s.id !== action.drilldownNodeId; }), drilldownLoading: __assign(__assign({}, state.drilldownLoading), (_d = {}, _d[action.drilldownNodeId] = false, _d)) });
        }
        case workspaceConstants_1.WORKSPACE_POST_DRILLDOWN_NODE_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], state.nodeElements.filter(function (s) { return !action.nodesToHide.includes(s.id); }), true), [
                    action.drilldownNode,
                ], false).sort(workspaceReducerFunctions_1.sortZIndex), edgeElements: __spreadArray(__spreadArray([], state.edgeElements, true), action.drilldownEdges, true) });
        case workspaceConstants_1.OPEN_MERGE_FIELDS_MODAL:
            return __assign(__assign({}, state), { mergeFieldsOpen: true });
        case workspaceConstants_1.CLOSE_MERGE_FIELDS_MODAL:
            return __assign(__assign({}, state), { mergeFieldsOpen: false });
        case workspaceConstants_1.GET_WORKSPACE_MERGE_FIELDS_SUCCESS:
            return __assign(__assign({}, state), { mergeFields: action.mergeFields, mergeLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_MERGE_FIELDS_FAILED:
            return __assign(__assign({}, state), { mergeLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_MERGE_FIELDS_LOADING:
            return __assign(__assign({}, state), { mergeLoading: true });
        case workspaceConstants_1.PUT_WORKSPACE_MERGE_FIELDS_SUCCESS:
            return __assign(__assign({}, state), { mergeLoading: false });
        case workspaceConstants_1.PUT_WORKSPACE_MERGE_FIELDS_FAILED:
            return __assign(__assign({}, state), { mergeLoading: false });
        case workspaceConstants_1.PUT_WORKSPACE_MERGE_FIELDS_LOADING:
            return __assign(__assign({}, state), { mergeLoading: true });
        case workspaceConstants_1.CHANGE_MERGE_FIELD:
            return __assign(__assign({}, state), { mergeFields: state.mergeFields.map(function (field) {
                    if (field.id === action.id) {
                        field.value = action.value;
                    }
                    return field;
                }) });
        case workspaceConstants_1.WORKSPACE_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { duplicateLoading: true });
        case workspaceConstants_1.WORKSPACE_DUPLICATE_FAILED:
            return __assign(__assign({}, state), { duplicateLoading: false });
        case workspaceConstants_1.WORKSPACE_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { workspaces: action.workspace
                    ? __spreadArray(__spreadArray([], state.workspaces, true), [action.workspace], false) : state.workspaces, duplicateLoading: false });
        case workspaceConstants_1.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_LOADING:
            return state;
        case workspaceConstants_1.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_SUCCESS:
            return __assign(__assign({}, state), { customRulesAlerts: action.customRulesAlerts });
        case workspaceConstants_1.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_FAILED:
            return state;
        case workspaceConstants_1.WORKSPACE_CHANGE_POWERPOINT_HELPER_SIZE:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    if (node.id === action.id) {
                        return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { size: action.size }) });
                    }
                    return node;
                }) });
        case workspaceConstants_1.WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    return node.id === action.id
                        ? __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { exitLevelPWC: action.exitLevel }) }) : node;
                }) });
        case workspaceConstants_1.CREATE_DUPLICATE_FROM_HANDLE_SUCCESS: {
            var _f = (0, workspaceReducerFunctions_1.seperateNodes)({
                payloadNodes: action.nodes,
                stateNodes: state.nodeElements,
            }), addedNodes = _f.addedNodes, updatedNodes = _f.updatedNodes;
            var _g = (0, workspaceReducerFunctions_1.seperateEdges)({
                payloadEdges: action.edges,
                stateEdges: state.edgeElements,
            }), addedEdges = _g.addedEdges, updatedEdges = _g.updatedEdges;
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], updatedNodes, true), addedNodes, true).sort(workspaceReducerFunctions_1.sortZIndex), edgeElements: __spreadArray(__spreadArray([], updatedEdges, true), addedEdges, true), mouseLoading: false });
        }
        case workspaceConstants_1.DUPLICATE_NODE_ON_EDGE_SUCCESS: {
            var _h = (0, workspaceReducerFunctions_1.seperateNodes)({
                payloadNodes: action.nodes,
                stateNodes: state.nodeElements,
            }), addedNodes = _h.addedNodes, updatedNodes = _h.updatedNodes;
            var _j = (0, workspaceReducerFunctions_1.seperateEdges)({
                payloadEdges: action.edges,
                stateEdges: state.edgeElements,
            }), addedEdges = _j.addedEdges, updatedEdges = _j.updatedEdges;
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], updatedNodes, true), addedNodes, true).sort(workspaceReducerFunctions_1.sortZIndex), edgeElements: __spreadArray(__spreadArray([], updatedEdges, true), addedEdges, true), mouseLoading: false });
        }
        case workspaceConstants_1.MAKE_STEP_FRAMES_ON_TAB_SUCCESS:
            return __assign(__assign({}, state), { workspaceTab: __assign(__assign({}, state.workspaceTab), { makeStepFrames: !state.workspaceTab.makeStepFrames }) });
        case (0, folderConstants_1.postFolderFailed)("workspaces"):
        case (0, folderConstants_1.putFolderTitleFailed)("workspaces"):
        case (0, folderConstants_1.putFolderLocationFailed)("workspaces"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("workspaces"):
        case (0, folderConstants_1.deleteFolderFailed)("workspaces"):
        case (0, folderConstants_1.duplicateFolderFailed)("workspaces"):
            return __assign(__assign({}, state), { loading: false });
        case (0, folderConstants_1.deleteFolderLoading)("workspaces"):
        case (0, folderConstants_1.putFolderLocationLoading)("workspaces"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("workspaces"):
        case (0, folderConstants_1.putFolderTitleLoading)("workspaces"):
        case (0, folderConstants_1.postFolderLoading)("workspaces"):
        case (0, folderConstants_1.duplicateFolderLoading)("workspaces"):
            return __assign(__assign({}, state), { loading: true });
        case (0, folderConstants_1.postFolderSuccess)("workspaces"):
            return __assign(__assign({}, state), { loading: false, workspaces: __spreadArray([action.folder], state.workspaces, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("workspaces"):
            return __assign(__assign({}, state), { loading: false, workspaces: state.workspaces.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("workspaces"):
            return __assign(__assign({}, state), { loading: false, workspaces: state.workspaces.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("workspaces"):
            return __assign(__assign({}, state), { loading: false, workspaces: state.workspaces.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("workspaces"): {
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loading: false, workspaces: state.workspaces
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loading: false, workspaces: state.workspaces
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.duplicateFolderSuccess)("workspaces"):
            return __assign(__assign({}, state), { loading: false, workspaces: __spreadArray(__spreadArray([], action.folder, true), state.workspaces, true) });
        case workspaceConstants_1.GET_EDITED_ELEMENTS_BY_USERS_SUCCESS: {
            var editedNodes = state.nodeElements.map(function (node) {
                if (action.nodes[node.id]) {
                    return (0, nodeEditable_1.addUserEditingToNode)(node, action.nodes[node.id]);
                }
                if (action.immutableGroupNodes[node.id]) {
                    node = (0, lodash_1.cloneDeep)(node);
                    node.deletable = false;
                }
                return node;
            });
            var editedEdges = state.edgeElements.map(function (edge) {
                if (action.edges[edge.id]) {
                    return __assign(__assign({}, edge), { data: __assign(__assign({}, edge.data), { editing: action.edges[edge.id] }), selected: false });
                }
                return edge;
            });
            return __assign(__assign({}, state), { nodeElements: editedNodes, edgeElements: editedEdges });
        }
        case workspaceConstants_1.REMOVE_USER_FROM_ELEMENTS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    if (node.data.editing === action.id) {
                        return (0, nodeEditable_1.removeUserEditingFromNode)(node);
                    }
                    return node;
                }), edgeElements: state.edgeElements.map(function (edge) {
                    var _a;
                    if (((_a = edge.data) === null || _a === void 0 ? void 0 : _a.editing) === action.id) {
                        return (0, nodeEditable_1.removeUserEditingFromEdge)(edge);
                    }
                    return edge;
                }) });
        case workspaceConstants_1.USER_TOGGLE_ACTIVE: {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.active = action.active;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case workspaceConstants_1.UPDATE_ROOM:
            return __assign(__assign({}, state), { room: action.room });
        case workspaceConstants_1.UPDATE_CURSOR_ROOM: {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.room = action.room;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case workspaceConstants_1.CLIENT_LOST_CONNECTION:
        case workspaceConstants_1.CLIENT_RECONNECTING:
            return state;
        case workspaceConstants_1.SET_IMMUTABLE_GROUP_NODES: {
            var nodeElements = state.nodeElements.map(function (node) {
                if (action.groupNodes[node.id]) {
                    node = (0, lodash_1.cloneDeep)(node);
                    node.deletable = false;
                }
                return node;
            });
            return __assign(__assign({}, state), { nodeElements: nodeElements });
        }
        case workspaceConstants_1.SET_MUTABLE_GROUP_NODES: {
            var nodeElements = state.nodeElements.map(function (node) {
                if (action.groupNodes[node.id]) {
                    node = (0, lodash_1.cloneDeep)(node);
                    delete node.deletable;
                }
                return node;
            });
            return __assign(__assign({}, state), { nodeElements: nodeElements });
        }
        case workspaceConstants_1.REMOVE_WORKSPACE_TAB_FOR_WORKSPACE:
            return __assign(__assign({}, state), { workspaceTabs: state.workspaceTabs.filter(function (tab) { return tab.value !== action.tabId; }) });
        case workspaceConstants_1.POST_WORKSPACE_TAB_FOR_WORKSPACE:
            return __assign(__assign({}, state), { workspaceTabs: __spreadArray(__spreadArray([], state.workspaceTabs, true), [action.tab], false) });
        case workspaceConstants_1.PUT_WORKSPACE_TAB_TITLE_FOR_WORKSPACE:
            return __assign(__assign({}, state), { workspaceTabs: state.workspaceTabs.map(function (tab) {
                    if (tab.value === action.tab.value) {
                        return action.tab;
                    }
                    return tab;
                }) });
        case workspaceConstants_1.USER_JOIN_ROOM: {
            var newMap = new Map(state.collaborators);
            return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, action.cursor) });
        }
        case workspaceConstants_1.USER_UPDATE_POSITION: {
            var cursor = state.collaborators.get(action.socketId);
            if (cursor) {
                cursor.position = { x: action.x, y: action.y };
                var newMap = new Map(state.collaborators);
                return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, cursor) });
            }
            return state;
        }
        case workspaceConstants_1.USER_UPDATE_POSITION_RELATIVE: {
            var cursor = state.collaborators.get(action.socketId);
            if (cursor) {
                cursor.position = {
                    x: action.x,
                    y: action.y,
                    height: action.height,
                    width: action.width,
                    scrollOffsetX: action.scrollOffsetX,
                    scrollOffsetY: action.scrollOffsetY,
                    scale: action.scale,
                };
                var newMap = new Map(state.collaborators);
                return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, cursor) });
            }
            return state;
        }
        case workspaceConstants_1.ADD_CONNECTED_USERS_CURSOR: {
            var newMap = action.cursors.reduce(function (map, cursor) { return map.set(cursor.id, cursor); }, state.collaborators);
            return __assign(__assign({}, state), { collaborators: newMap });
        }
        case workspaceConstants_1.REMOVE_CONNECTED_USER: {
            var newMap = new Map(state.collaborators);
            newMap.delete(action.id);
            return __assign(__assign({}, state), { collaborators: newMap });
        }
        case workspaceConstants_1.USER_EDIT_EDGES:
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (el) {
                    if (action.edges.includes(el.id)) {
                        return __assign(__assign({}, el), { data: __assign(__assign({}, el.data), { editing: action.id }), selected: false });
                    }
                    return el;
                }) });
        case workspaceConstants_1.USER_FINISH_EDIT_EDGES:
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (el) {
                    if (action.edges.includes(el.id)) {
                        return (0, nodeEditable_1.removeUserEditingFromEdge)(el);
                    }
                    return el;
                }) });
        case workspaceConstants_1.USER_EDIT_NODES:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (el) {
                    if (el.data.editing === action.id) {
                        el = (0, nodeEditable_1.removeUserEditingFromNode)(el);
                    }
                    if (action.nodes.includes(el.id)) {
                        return (0, nodeEditable_1.addUserEditingToNode)(el, action.id);
                    }
                    return el;
                }) });
        case workspaceConstants_1.USER_FINISH_EDIT_NODES:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (el) {
                    if (action.nodes.includes(el.id)) {
                        return (0, nodeEditable_1.removeUserEditingFromNode)(el);
                    }
                    return el;
                }) });
        case workspaceConstants_1.CLEAR_USER_CURSORS:
            return __assign(__assign({}, state), { collaborators: new Map() });
        case workspaceConstants_1.SAVE_NODE_POSITION_SUCCESS: {
            var updatedNodes = (0, workspaceReducerFunctions_1.seperateNodes)({
                payloadNodes: action.nodes,
                stateNodes: state.nodeElements,
            }).updatedNodes;
            var updatedEdges = (0, workspaceReducerFunctions_1.seperateEdges)({
                payloadEdges: action.edges,
                stateEdges: state.edgeElements,
            }).updatedEdges;
            return __assign(__assign({}, state), { nodeElements: updatedNodes, edgeElements: updatedEdges, mouseLoading: false });
        }
        case workspaceConstants_1.SOFT_DELETE_WORKSPACE_EDGES_SUCCESS: {
            var nodeElements_2 = {};
            state.nodeElements.forEach(function (node) {
                var _a;
                var isNodeNotDeleted = !((_a = action.deletedNodeIds) === null || _a === void 0 ? void 0 : _a.has(node.id));
                if (isNodeNotDeleted) {
                    nodeElements_2[node.id] = __assign({}, node);
                }
            });
            (action.alteredNodes || []).forEach(function (node) {
                if (node.id in nodeElements_2) {
                    nodeElements_2[node.id] = node;
                }
            });
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.filter(function (edge) { var _a; return !((_a = action.deletedEdges) === null || _a === void 0 ? void 0 : _a.includes(edge.id)); }), nodeElements: Object.values(nodeElements_2).sort(workspaceReducerFunctions_1.sortZIndex), mouseLoading: false });
        }
        case workspaceConstants_1.SOFT_DELETE_WORKSPACE_NODES_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements
                    .filter(function (node) { var _a; return !((_a = action.deletedNodes) === null || _a === void 0 ? void 0 : _a.includes(node.id)); })
                    .sort(workspaceReducerFunctions_1.sortZIndex), mouseLoading: false });
        case workspaceConstants_1.HANDLE_DETACH_NODES_SUCCESS: {
            var noParent = action.payload.elements.map(function (node) { return (__assign(__assign({}, node), { parentNode: undefined, extent: undefined })); });
            var updatedNodes = (0, workspaceReducerFunctions_1.seperateNodes)({
                payloadNodes: noParent,
                stateNodes: state.nodeElements,
            }).updatedNodes;
            return __assign(__assign({}, state), { nodeElements: updatedNodes.filter(function (node) { return node.id !== action.payload.parentId; }) });
        }
        case workspaceConstants_1.HANDLE_ATTACH_NODE_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    if (node.id === action.element.id) {
                        return action.element;
                    }
                    return node;
                }) });
        case workspaceConstants_1.TOGGLE_DOCUMENT_MODAL:
            return __assign(__assign({}, state), { showDocumentModal: action.show });
        case workspaceConstants_1.OPEN_INFO_MODAL:
            return __assign(__assign({}, state), { infoModalData: action.data });
        case workspaceConstants_1.CLOSE_INFO_MODAL:
            return __assign(__assign({}, state), { infoModalData: null });
        case workspaceConstants_1.SET_WORKSPACE_EDITABLE:
            return __assign(__assign({}, state), { editable: action.editable });
        case workspaceConstants_1.CLEAR_STATE:
            return __assign(__assign({}, initialState), { workspaces: state.workspaces });
        case workspaceConstants_1.SET_DEFAULT_RELATIONSHIP:
            return __assign(__assign({}, state), { defaultRelationship: {
                    relationship: action.option,
                    value: "",
                } });
        case workspaceConstants_1.SET_DEFAULT_RELATIONSHIP_VALUE:
            return __assign(__assign({}, state), { defaultRelationship: __assign(__assign({}, state.defaultRelationship), { value: action.value }) });
        case workspaceConstants_1.IMPORT_WORKSPACE_FILES_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case workspaceConstants_1.IMPORT_WORKSPACE_FILES_SUCCESS: {
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], state.nodeElements, true), action.nodes, true).sort(workspaceReducerFunctions_1.sortZIndex), mouseLoading: false });
        }
        case workspaceConstants_1.IMPORT_WORKSPACE_FILES_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.WORKSPACE_POST_FILE_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: false, nodeElements: __spreadArray(__spreadArray([], state.nodeElements, true), [action.node], false).sort(workspaceReducerFunctions_1.sortZIndex) });
        case workspaceConstants_1.WORKSPACE_POST_FILE_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case workspaceConstants_1.IMPORT_FILES_ON_NODE_SUCCESS:
            return __assign(__assign({}, state), { documentsLoading: false });
        case workspaceConstants_1.EDGE_LABEL_MOVE_SUCCESS:
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) {
                    if (edge.id === action.edge.id) {
                        return action.edge;
                    }
                    return edge;
                }), mouseLoading: false });
        case workspaceConstants_1.EDGE_PATH_DRAG_SUCCESS:
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) {
                    if (edge.id === action.edge.id) {
                        return action.edge;
                    }
                    return edge;
                }), mouseLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_LEGEND_LOADING:
            return __assign(__assign({}, state), { legendLoading: true });
        case workspaceConstants_1.GET_WORKSPACE_LEGEND_SUCCESS:
            return __assign(__assign({}, state), { legend: action.legend, legendLoading: false });
        case workspaceConstants_1.GET_WORKSPACE_LEGEND_FAILED:
            return __assign(__assign({}, state), { legendLoading: false });
        case workspaceConstants_1.PUT_WORKSPACE_LEGEND_LOADING:
            return __assign(__assign({}, state), { legendIdLoading: action.id });
        case workspaceConstants_1.PUT_WORKSPACE_LEGEND_SUCCESS:
            return __assign(__assign({}, state), { legend: state.legend.map(function (l) {
                    if (l.id === action.id) {
                        l.name = action.name;
                    }
                    return l;
                }), legendIdLoading: null });
        case workspaceConstants_1.PUT_WORKSPACE_LEGEND_FAILED:
            return __assign(__assign({}, state), { legendIdLoading: null });
        case workspaceConstants_1.ALIGN_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    var newNode = action.elements.find(function (n) { return n.id === node.id; });
                    if (newNode) {
                        return newNode;
                    }
                    return node;
                }), mouseLoading: false });
        case workspaceConstants_1.WORKSPACE_UNDO:
            return __assign(__assign(__assign({}, state), { undo: __assign(__assign({}, state.undo), { stack: state.undo.stack.slice(0, -1), isPossible: state.undo.stack.length - 1 > 0 }), redo: __assign(__assign({}, state.redo), { stack: __spreadArray(__spreadArray([], state.redo.stack, true), [action.payload.rollback], false), isPossible: true }) }), (0, workspaceReducerFunctions_1.default)({
                changes: action.payload.changes,
                state: state,
            }));
        case workspaceConstants_1.WORKSPACE_REDO:
            return __assign(__assign(__assign({}, state), { redo: __assign(__assign({}, state.redo), { stack: state.redo.stack.slice(0, -1), isPossible: state.redo.stack.length - 1 > 0 }), undo: __assign(__assign({}, state.undo), { stack: __spreadArray(__spreadArray([], state.undo.stack, true), [action.payload.rollback], false), isPossible: true }) }), (0, workspaceReducerFunctions_1.default)({
                changes: action.payload.changes,
                state: state,
            }));
        case workspaceConstants_1.WORKSPACE_UNDO_APPEND: {
            var undo = Array.isArray(action.payload) ? action.payload : [action.payload];
            return __assign(__assign({}, state), { undo: __assign(__assign({}, state.undo), { stack: __spreadArray(__spreadArray([], state.undo.stack, true), undo, true), isPossible: true }), redo: __assign(__assign({}, state.redo), { stack: [], isPossible: false }) });
        }
        case workspaceConstants_1.WORKSPACE_UNDO_RESET:
            return __assign(__assign({}, state), { undo: __assign(__assign({}, state.undo), { stack: [], isPossible: false }) });
        case workspaceConstants_1.WORKSPACE_REDO_RESET:
            return __assign(__assign({}, state), { redo: __assign(__assign({}, state.redo), { stack: [], isPossible: false }) });
        // WS undo/redo
        case workspaceConstants_1.WORKSPACE_UNDO_PAYLOAD:
        case workspaceConstants_1.WORKSPACE_REDO_PAYLOAD:
            return __assign(__assign({}, state), (0, workspaceReducerFunctions_1.default)({
                changes: action.payload.changes,
                state: state,
            }));
        case workspaceConstants_1.GET_RECENT_WORKSPACES_SUCCESS:
            return __assign(__assign({}, state), { recentWorkspaces: action.workspaces, recentLoading: false });
        case workspaceConstants_1.GET_RECENT_WORKSPACES_FAILED:
            return __assign(__assign({}, state), { recentLoading: false });
        case workspaceConstants_1.GET_RECENT_WORKSPACES_LOADING:
            return __assign(__assign({}, state), { recentLoading: true });
        case workspaceConstants_1.CLEAR_RECENT_WORKSPACES:
            return __assign(__assign({}, state), { recentWorkspaces: [], recentLoading: false });
        case workspaceConstants_1.ADD_TEMPORARY_CONNECTION: {
            var id_1 = action.id, connection_1 = action.connection, customHandle_1 = action.customHandle, edgeType_1 = action.edgeType;
            var customHandleNodeId_1 = connection_1.sourceHandle === useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID
                ? connection_1.source
                : connection_1.target;
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) {
                    if (edge.id === id_1) {
                        if (edgeType_1 === reactFlow_1.EdgeVariants.SMOOTHSTEP && edge.data) {
                            edge.data.pathKnots = [];
                        }
                        return __assign(__assign({}, edge), connection_1);
                    }
                    return edge;
                }), nodeElements: customHandle_1
                    ? state.nodeElements.map(function (node) {
                        if (node.id === customHandleNodeId_1) {
                            node.data = __assign(__assign({}, node.data), { handles: __spreadArray(__spreadArray([], (node.data.handles || []), true), [
                                    __assign({ id: -1, nodeId: parseInt(node.id, 10), createdAt: new Date().toISOString(), updatedAt: new Date().toISOString() }, customHandle_1),
                                ], false) });
                        }
                        return node;
                    })
                    : state.nodeElements });
        }
        case workspaceConstants_1.REMOVE_TEMPORARY_CONNECTION: {
            var id_2 = action.id, oldConnection_1 = action.oldConnection, connection = action.connection;
            var customHandleNodeId_2 = connection.sourceHandle === useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID
                ? connection.source
                : connection.targetHandle === useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID
                    ? connection.target
                    : null;
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) {
                    if (edge.id === id_2) {
                        return __assign(__assign({}, edge), oldConnection_1);
                    }
                    return edge;
                }), nodeElements: customHandleNodeId_2 === null
                    ? state.nodeElements
                    : state.nodeElements.map(function (node) {
                        if (node.id === customHandleNodeId_2) {
                            node.data = __assign(__assign({}, node.data), { handles: (node.data.handles || []).filter(function (handle) { return handle.id !== -1; }) });
                        }
                        return node;
                    }) });
        }
        case workspaceConstants_1.WORKSPACE_UNDO_LOADING:
        case workspaceConstants_1.WORKSPACE_REDO_LOADING:
            return __assign(__assign({}, state), { isUndoingOrRedoing: true });
        case workspaceConstants_1.WORKSPACE_DESELECT_EDGES:
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) { return (__assign(__assign({}, edge), { selected: false })); }) });
        case workspaceConstants_1.WORKSPACE_UNDO_DONE:
        case workspaceConstants_1.WORKSPACE_REDO_DONE:
            return __assign(__assign({}, state), { isUndoingOrRedoing: false });
        case workspaceConstants_1.SET_IS_WORKSPACE_PUBLIC:
            return __assign(__assign({}, state), { isPublic: action.isPub });
        case workspaceConstants_1.UPLOAD_AI_IMAGE_LOADING:
        case workspaceConstants_1.AI_PROMPT_LOADING:
            return __assign(__assign({}, state), { aiLoading: true });
        case workspaceConstants_1.AI_PROMPT_SUCCESS:
        case workspaceConstants_1.UPLOAD_AI_IMAGE_SUCCESS:
        case workspaceConstants_1.UPLOAD_AI_IMAGE_FAILED:
        case workspaceConstants_1.AI_PROMPT_FAILED:
            return __assign(__assign({}, state), { aiLoading: false });
        case workspaceConstants_1.WORKSPACE_UPDATE_FILE_PROGRESS_UPLOADS: {
            var newFiles = state.files.map(function (file) {
                if (("id" in file.file && file.file.id === action.name) ||
                    file.file.name === action.name) {
                    return __assign(__assign({}, file), { progress: action.progress });
                }
                return file;
            });
            return __assign(__assign({}, state), { files: newFiles });
        }
        case workspaceConstants_1.WORKSPACE_NODE_ADD_DOCUMENTS_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    if ("documents" in node.data && node.id === action.nodeId.toString()) {
                        var data = __assign(__assign({}, node.data), { documents: __spreadArray(__spreadArray([], node.data.documents, true), action.documents, true) });
                        return __assign(__assign({}, node), { data: data });
                    }
                    return node;
                }) });
        case workspaceConstants_1.WORKSPACE_NODE_ADD_DOCUMENTS_LOADING:
            return __assign(__assign({}, state), { isFileUploadLoading: true });
        case workspaceConstants_1.WORKSPACE_NODE_ADD_DOCUMENTS_FAILED:
            return __assign(__assign({}, state), { isFileUploadLoading: false });
        case workspaceConstants_1.WORKSPACE_SET_FILE_UPLOADS:
            return __assign(__assign({}, state), { files: action.files });
        case workspaceConstants_1.CLEAR_UNSETTLED_LABELS:
            return __assign(__assign({}, state), { unsettledLabels: initialState.unsettledLabels });
        default: {
            return state;
        }
    }
}
exports.default = reducer;
